<template>
  <div>
    <CooperateCalendarEventQuickAddVue @create="reload" />
    <ListView
      @reload="reload"
      @navDetails="actionDetails"
      :search.sync="search"
      :data="data"
      :page.sync="page"
      :header="header"
      :is-fluid="true"
    >
      <template #action><span></span></template>
      <template v-slot:item.date="{ item }">
        {{ item.date | moment('YYYY/MM/DD') }}
      </template>
      <template v-slot:item.operation="{ item }">
        <v-btn small @click="deleteItem(item)" color="error"
          ><v-icon>mdi-trash-can</v-icon> Delete</v-btn
        >
      </template>
    </ListView>
  </div>
</template>

<script>
import listViewPageMixin from '@/services/listViewPageMixin';
import { mapActions, mapGetters } from 'vuex';
import { RESTFUL } from '@/data/constants';
import { crudDelete } from '@/api/crud';
import api from '@/api';
import { get } from 'lodash';
import CooperateCalendarEventQuickAddVue from './CooperateCalendarEventQuickAdd.vue';
export default {
  name: 'transactions',
  components: {
    CooperateCalendarEventQuickAddVue,
  },
  data() {
    return {
      sort: { date: -1, created_at: -1 },
    };
  },
  mixins: [listViewPageMixin],
  computed: {
    ...mapGetters('auth', ['role']),
    restfulURL: () => RESTFUL.cooperateCalendarEvents.list,
    filter() {
      let out = {};
      return out;
    },
    mergeParams() {
      return {};
    },
    header() {
      return [
        { text: 'Date', value: 'date' },
        { text: 'Remarks', value: 'remarks' },
        { text: 'Name', value: 'eventName' },
        { text: '', value: 'operation' },
        // { text: 'Type', value: 'eventType' },
      ];
    },
  },
  methods: {
    get,
    ...mapActions('alert', ['updateAlertMessage']),
    actionDetails(x) {},
    async deleteItem(item) {
      if (!confirm('confirm delete?')) {
        return;
      }
      await crudDelete(api, RESTFUL.cooperateCalendarEvents.create, item._id);
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Saved',
        type: 'success',
        color: 'success',
      });
      this.reload();
    },
  },
};
</script>
