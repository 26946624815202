<template>
  <ValidationObserver ref="form" v-slot="{ invalid }">
    <v-container>
      <v-row>
        <v-col cols="12">
          <custom-label item-field-name="ClockIn Type" />
          <v-radio-group mandatory v-model="model.clockInType" row>
            <v-radio class="mr-16" label="ClockIn" value="clockIn" />
            <v-radio class="mr-16" label="ClockOut" value="clockOut" />
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="6">
          <custom-label item-field-name="Staff" />
          <ValidationProvider v-slot="{ errors }" name="Staff" rules="required">
            <autocomplete
              label="Staff"
              :path="'toca/admin/Profiles'"
              :params="{
                sort: { created_at: -1 },
                filter: { status: ProfileStatus.accepted, role: { $ne: roles.client } },
              }"
              item-text="name"
              item-value="_id"
              v-model="model.staff"
              :error-messages="errors"
              :clearable="true"
              :has-items-slot="true"
            >
              <template v-slot:items="content">
                <span>{{
                  get(content, 'item.name', '') + ' ' + displayPhone(get(content, 'item.phone', ''))
                }}</span>
              </template>
            </autocomplete>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6"></v-col>
        <v-col cols="12" md="6">
          <custom-label item-field-name="Date" />
          <ValidationProvider v-slot="{ errors }" name="Date" rules="required">
            <DatePickerWrapperInputMenu
              outlined
              v-model="clockInOutDate"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <custom-label item-field-name="Time" />
          <ValidationProvider v-slot="{ errors }" name="Time" rules="required">
            <TimePickerWrapperInputMenu
              outlined
              v-model="clockInOutTime"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <!-- <v-col cols="6">
          <custom-label item-field-name="Status" />
          <v-row>
            <v-col> <v-checkbox label="Late"></v-checkbox></v-col>
            <v-col> <v-checkbox label="Early Leave"></v-checkbox></v-col>
          </v-row>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn color="primary" @click="save">Save</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </ValidationObserver>
</template>

<script>
import api from '@/api';
import moment from 'moment';
import ApiError from '@/api/ApiError';
import { uploadSingleAttachment } from '@/api/attachment';
import { crudCreate } from '@/api/crud';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { leaveApplicationOption, RESTFUL } from '@/data/constants';
import editDialogMixin from '@/services/editDialogMixin';
import DatePickerWrapperInputMenu from '@/components/DatePickerWrapperInputMenu';
import TimePickerWrapperInputMenu from '@/components/TimePickerWrapperInputMenu';
import { mapActions, mapGetters } from 'vuex';
import { leaveTypeOption } from '@/data/optionsConstants';
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import { displayPhone } from '@/services/filters';
import { get } from 'lodash';
import { calculateWorkingDays, calculateWorkingHours } from '@/services/utils';
import { masterGetHolidays } from '@/api/cooperateCalendarEvent';
import { ProfileStatus } from '@/api/auth/membership';
import { createClockInRecord } from '@/api/clockInRecords';
import { roles } from '@/data/constants';

export default {
  name: 'ClockInDetails',
  components: { CustomLabel, Autocomplete, DatePickerWrapperInputMenu, TimePickerWrapperInputMenu },

  data() {
    return {
      roles,
      ProfileStatus,
    };
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          created_at: moment().toISOString(),
        };
      },
    },
  },
  computed: {
    clockInOutDate: {
      get() {
        return moment(this.model.created_at).format('YYYY-MM-DD');
      },
      set(v) {
        const newDate = moment(v);
        const [year, month, date] = [newDate.year(), newDate.month(), newDate.date()];
        const currentDate = moment(this.model.created_at);
        currentDate.set({ year, month, date });
        this.model.created_at = currentDate.toDate();
      },
    },
    clockInOutTime: {
      get() {
        return moment(this.model.created_at).format('hh:mm');
      },
      set(v) {
        const currentDate = moment(this.model.created_at);
        const [hour, minute] = v.split(':');
        currentDate.set({ hour, minute, second: 0, millisecond: 0 });
        this.model.created_at = currentDate.toDate();
      },
    },
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    get,
    displayPhone,
    moment,
    async save() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        await createClockInRecord(api, this.model);
        const msg = 'Record Created';
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: msg,
          type: 'success',
          color: 'success',
        });
        this.$emit('create', this.model);
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: error,
          type: 'error',
          color: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
