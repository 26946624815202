var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListView',{attrs:{"title":"Count inventory","search":_vm.search,"data":_vm.data,"header":_vm.header,"enableCreate":false},on:{"reload":_vm.reload,"navDetails":_vm.actionDetails,"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.startAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.startAt))+" ")]}},{key:"item.endAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.endAt))+" ")]}},{key:"item.unidentifiedTotalStock",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toThousandFilter")(item.unidentifiedTotalStock))+" ")]}},{key:"item.identifiedTotalStock",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toThousandFilter")(item.identifiedTotalStock))+" ")]}},{key:"item._match",fn:function(ref){
var item = ref.item;
return [(item.status === _vm.stockCountRecordStatus.completed)?_c('div',[(item._unmatched)?_c('div',[_c('v-chip',{staticClass:"error my-2",attrs:{"label":"","small":""}},[_vm._v("Unmatched")]),_c('div',{staticClass:"caption"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Stock Alignment:")]),_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(item.alignStockBy.name))],1),_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clock-outline")]),_vm._v(" "+_vm._s(_vm._f("moment")(item.alignStockAt)))],1)])],1):_c('div',[_c('v-chip',{staticClass:"success my-2",attrs:{"label":"","small":""}},[_vm._v("Matched")])],1)]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }