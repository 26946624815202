<template>
  <v-container>
    <div><h2>System Config</h2></div>
    <v-divider class="mb-5" />
    <div class="d-flex justify-end">
      <v-btn
        class="my-3 mx-3"
        min-height="56"
        color="primary"
        @click="goToGenerateUUID"
        :loading="generateUUIDloading"
        >Generate UUID
      </v-btn>
    </div>
    <v-card v-if="userPreference">
      <ValidationObserver ref="userPreferenceForm" v-slot="{ invalid, validate }">
        <v-card-title>Operation Setting</v-card-title>
        <v-card-text>
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{ min_value: 1, numeric: true }"
            name="Max Servicing Capacity"
          >
            <v-text-field
              v-model.number="userPreference.maxServicingCapacity"
              :error="!!errors.length"
              :error-messages="errors"
              label="Max Servicing Capacity"
              min="1"
              type="number"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{ min: 0, numeric: true }"
            name="Feedback Award"
          >
            <v-text-field
              v-model.number="userPreference.feedbackAward"
              :error="!!errors.length"
              :error-messages="errors"
              label="Feedback Award"
              type="number"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{ min_value: 1, numeric: true }"
            name="Default Booking Duration"
          >
            <v-text-field
              v-model.number="userPreference.defaultBookingDuration"
              :error="!!errors.length"
              :error-messages="errors"
              label="Default Booking Duration (minutes)"
              min="1"
              type="number"
            />
          </ValidationProvider>
          <custom-label item-field-name="Opening Hours" />
          <!--          {{ localStart || '-' }} ~ {{ localEnd || '-' }}-->
          <v-row>
            <v-col :cols="3">
              <v-select outlined v-model="localStart" :items="time" />
            </v-col>

            <v-col :cols="3">
              <v-select outlined v-model="localEnd" :items="time" />
            </v-col>
          </v-row>

          <v-checkbox
            class="px-3 flex-grow-0 flex-shrink-0"
            v-model="userPreference.isSendBookingSuccessfulSms"
            color="primary"
            label="To send confirmation SMS when booking is created by client"
          ></v-checkbox>
          <!-- <ValidationProvider
            v-slot="{ errors }"
            :rules="{ required: true }"
            name="Default Booking Duration"
          >
            <v-text-field
              v-model.trim="userPreference.scanToLoginURL"
              :error-messages="errors"
              label="Scan To Login URL"
              type="text"
            />
          </ValidationProvider> -->
          <!--          <v-dialog ref="timeDialog" v-model="timeDialog" width="580px">-->
          <!--            <template v-slot:activator="{ on }">-->
          <!--              <v-icon v-on="on">mdi-clock-outline</v-icon>-->
          <!--            </template>-->
          <!--            <v-card>-->
          <!--              <v-card-text class="px-0">-->
          <!--                <v-row class="mx-0">-->
          <!--                  <v-col :cols="6" class="px-0">-->
          <!--                    <v-time-picker v-if="timeDialog" v-model="localStart" format="24hr">-->
          <!--                    </v-time-picker>-->
          <!--                  </v-col>-->
          <!--                  <v-col :cols="6" class="px-0">-->
          <!--                    <v-time-picker v-if="timeDialog" v-model="localEnd" format="24hr">-->
          <!--                    </v-time-picker>-->
          <!--                  </v-col>-->
          <!--                </v-row>-->
          <!--              </v-card-text>-->
          <!--              <v-card-actions>-->
          <!--                <v-btn @click="timeDialog = false">Done</v-btn>-->
          <!--              </v-card-actions>-->
          <!--            </v-card>-->
          <!--          </v-dialog>-->
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="invalid" color="primary" @click="validate().then(updateUserPreference)">
            Update
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
    <v-card class="mt-4" v-if="printOrderReceiptPreference">
      <ValidationObserver ref="printOrderReceiptPreferenceForm" v-slot="{ invalid, validate }">
        <v-card-title>Printer Setting</v-card-title>
        <v-card-text>
          <!-- autoPrintFromPos: boolean;
  autoPrintOfflinePayment: boolean; -->
          <v-checkbox
            class="px-3 flex-grow-0 flex-shrink-0"
            v-model="printOrderReceiptPreference.autoPrintFromPos"
            color="primary"
            label="Print Receipt and Restaurant Order By Pos"
          >
          </v-checkbox>
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{ required: true }"
            name="Default Booking Duration"
          >
            <v-text-field
              v-model.trim="printOrderReceiptPreference.printerMacAddress"
              :error-messages="errors"
              label="Receipt Printer MacAddress"
              type="text"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{ required: true }"
            name="Default Booking Duration"
          >
            <v-text-field
              v-model.trim="printOrderReceiptPreference.restaurantOrderPrinterMacAddress"
              :error-messages="errors"
              label="Restaurant Order Printer MacAddress"
              type="text"
            />
          </ValidationProvider>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="invalid"
            color="primary"
            @click="validate().then(updatePrintOrderReceiptPreference)"
          >
            Update
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
    <!-- hide as request on 11 Jul 2022
    <v-spacer class="mb-5" />
    <v-card v-if="paymentPreference">
      <ValidationObserver ref="paymentPreferenceForm" v-slot="{ invalid, validate }">
        <v-card-title>Price Setting</v-card-title>
        <v-card-text>
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{ min: 0, numeric: true }"
            name="Hair Cut Price"
          >
            <v-text-field
              v-model.number="paymentPreference.hairCutPrice"
              :error="!!errors.length"
              :error-messages="errors"
              label="Hair Cut Price"
              type="number"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{ min: 0, numeric: true }"
            name="Master Specified Price"
          >
            <v-text-field
              v-model.number="paymentPreference.isMasterSpecified"
              :error="!!errors.length"
              :error-messages="errors"
              label="Requested Facility Price"
              type="number"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{ min: 0, numeric: true }"
            name="Different Hair Length Fee"
          >
            <v-text-field
              v-model.number="paymentPreference.differentHairLengthFee"
              :error="!!errors.length"
              :error-messages="errors"
              label="Different Hair Length Fee"
              type="number"
            />
          </ValidationProvider>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="invalid"
            color="primary"
            @click="validate().then(updatePaymentPreference)"
          >
            Update
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
    end hide as request on 11 Jul 2022 -->
    <!-- hide as request on 11 Jul 2022
    <v-spacer class="mb-5" />
    <v-card v-if="adminPreference">
      <v-card-title>Service Details Setting</v-card-title>
      <v-card-text>
        <template>
          <h3>
            Gray Hair Condition
            <v-btn
              v-if="true /* show as pre request on 20220211 18:14*/"
              icon
              @click="openAndUpload('hairConditionReferencePhoto')"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </h3>
          <div class="d-flex flex-wrap">
            <div
              v-for="(item, i) in adminPreference.hairConditionReferencePhoto"
              :key="`a` + i"
              class="mr-3"
            >
              <img-with-placeholder :item="item" />
              <div>
                <div class="d-flex justify-end" style="margin-top: -10px">
                  <v-btn icon @click="openAndUpload('hairConditionReferencePhoto', item, i)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="debug"
                    icon
                    @click="adminPreference.hairConditionReferencePhoto.splice(i, 1)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template>
          <h3>Scalp Condition</h3>
          <div class="mt-2" />
          <v-chip
            class="mr-5 mb-5"
            label
            style="font-weight: bold"
            color="#96734B"
            outlined
            close
            close-icon="mdi-close-circle"
            :key="`b` + index"
            @click:close="() => adminPreference.scalpCondition.splice(index, 1)"
            v-for="(item, index) in adminPreference.scalpCondition"
            >{{ item }}
          </v-chip>
          <v-text-field
            flat
            dense
            outlined
            v-model="scalpCondition"
            @keydown.enter="
              () => {
                adminPreference.scalpCondition.push(scalpCondition);
                scalpCondition = null;
              }
            "
            append-outer-icon="mdi-plus-circle-outline"
            @click:append-outer="addScalpConditionLabel"
          >
          </v-text-field>
        </template>
        <template>
          <h3>
            What had been done on hair
            <v-btn
              v-if="true /* show as pre request on 20220211 18:14*/"
              icon
              @click="openAndUpload('workDone')"
              ><v-icon>mdi-plus</v-icon>
            </v-btn>
          </h3>
          <div class="d-flex flex-wrap">
            <div v-for="(item, i) in adminPreference.workDone" :key="`c` + i" class="mr-3">
              <img-with-placeholder :item="item" />
              <div>
                <div class="d-flex justify-end" style="margin-top: -10px">
                  <v-btn icon @click="openAndUpload('workDone', item, i)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn v-if="debug" icon @click="adminPreference.workDone.splice(i, 1)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </template>
        end hide as request on 11 Jul 2022 -->
    <!--    ** hide as pre request on 4 Feb2022   -->
    <!--    ** show as pre request on 20220211 18:14   -->
    <!--        <template>-->
    <!--          <h3>Hair Volume</h3>-->
    <!--          <div class="mt-2" />-->
    <!--          <v-chip-->
    <!--            class="mr-5 mb-5"-->
    <!--            label-->
    <!--            style="font-weight: bold"-->
    <!--            color="#96734B"-->
    <!--            outlined-->
    <!--            close-->
    <!--            @click:close="() => adminPreference.volume.splice(index, 1)"-->
    <!--            close-icon="mdi-close-circle"-->
    <!--            :key="`d` + index"-->
    <!--            v-for="(item, index) in adminPreference.volume"-->
    <!--            >{{ item }}-->
    <!--          </v-chip>-->
    <!--          <v-text-field-->
    <!--            flat-->
    <!--            dense-->
    <!--            outlined-->
    <!--            v-model="volume"-->
    <!--            @keydown.enter="-->
    <!--              () => {-->
    <!--                adminPreference.volume.push(volume);-->
    <!--                volume = null;-->
    <!--              }-->
    <!--            "-->
    <!--            append-outer-icon="mdi-plus-circle-outline"-->
    <!--            @click:append-outer="-->
    <!--              () => {-->
    <!--                adminPreference.volume.push(volume);-->
    <!--                volume = null;-->
    <!--              }-->
    <!--            "-->
    <!--          >-->
    <!--          </v-text-field>-->
    <!--        </template>-->
    <!--        <template>-->
    <!--          <h3>Hair Condition</h3>-->
    <!--          <div class="mt-2" />-->
    <!--          <v-chip-->
    <!--            class="mr-5 mb-5"-->
    <!--            label-->
    <!--            style="font-weight: bold"-->
    <!--            color="#96734B"-->
    <!--            outlined-->
    <!--            close-->
    <!--            close-icon="mdi-close-circle"-->
    <!--            :key="`e` + index"-->
    <!--            v-for="(item, index) in adminPreference.condition"-->
    <!--            @click:close="() => adminPreference.condition.splice(index, 1)"-->
    <!--            >{{ item }}-->
    <!--          </v-chip>-->
    <!--          <v-text-field-->
    <!--            flat-->
    <!--            dense-->
    <!--            outlined-->
    <!--            v-model="condition"-->
    <!--            @keydown.enter="-->
    <!--              () => {-->
    <!--                adminPreference.condition.push(condition);-->
    <!--                condition = null;-->
    <!--              }-->
    <!--            "-->
    <!--            append-outer-icon="mdi-plus-circle-outline"-->
    <!--            @click:append-outer="-->
    <!--              () => {-->
    <!--                adminPreference.condition.push(condition);-->
    <!--                condition = null;-->
    <!--              }-->
    <!--            "-->
    <!--          >-->
    <!--          </v-text-field>-->
    <!--        </template>-->
    <!--    ** end hide as pre request on 4 Feb2022   -->
    <!-- hide as request on 11 Jul 2022
          <template>
          <h3>
            Expected Color Result
            <v-btn
              v-if="true /* show as pre request on 20220211 18:14*/"
              icon
              @click="openAndUpload('expectedColor')"
              ><v-icon>mdi-plus</v-icon>
            </v-btn>
          </h3>
          <div class="d-flex flex-wrap">
            <div v-for="(item, i) in adminPreference.expectedColor" :key="`f` + i" class="mr-3">
              <img-with-placeholder :item="item" />
              <div>
                <div class="d-flex justify-end" style="margin-top: -10px">
                  <v-btn icon @click="openAndUpload('expectedColor', item, i)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn v-if="debug" icon @click="adminPreference.expectedColor.splice(i, 1)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template>
          <h3>
            Color Options
            <v-btn
              v-if="true /* show as pre request on 20220211 18:14*/"
              icon
              @click="openAndUpload('colorOptions')"
              ><v-icon>mdi-plus</v-icon>
            </v-btn>
          </h3>
          <div class="d-flex flex-wrap">
            <div v-for="(item, i) in adminPreference.colorOptions" :key="`f` + i" class="mr-3">
              <div style="max-width: 150px"> -->
    <!--                <span>{{ item.description }}</span>-->
    <!-- <span>{{ item.label }}</span>
                <div
                  :style="{
                    backgroundColor: get(item, ['value'], '#ffffff'),
                  }"
                  class="flex-shrink-0"
                  style="width: 16px; height: 16px; display: inline-block; margin-left: 12px"
                ></div>
              </div>
              <div>
                <div class="d-flex justify-end" style="margin-top: -10px">
                  <v-btn icon @click="openAndUpload('colorOptions', item, i)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn v-if="debug" icon @click="adminPreference.colorOptions.splice(i, 1)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="updateAdminPreference">Update</v-btn>
      </v-card-actions>
    </v-card>
    end hide as request on 11 Jul 2022 -->
    <v-spacer class="mb-5" />
    <v-card>
      <ValidationObserver ref="userPreferenceForm" v-slot="{ invalid, validate }">
        <v-card-title>Statutory holiday </v-card-title>
        <v-card-text>
          <CooperateCalendarEvent />
        </v-card-text>
      </ValidationObserver>
    </v-card>
    <v-dialog v-model="editDialog" width="60%" max-width="100vw">
      <color-options-card
        v-if="editDialog && type === 'colorOptions'"
        :item="editItem"
        @add="
          x => {
            editDialogCallBack && editDialogCallBack(x);
            editDialog = false;
          }
        "
        @close="
          () => {
            editDialogCallBack && editDialogCallBack(null);
            editDialog = false;
          }
        "
      />
      <AddPhotoCard
        v-else-if="editDialog"
        :item="editItem"
        @add="
          x => {
            editDialogCallBack && editDialogCallBack(x);
            editDialog = false;
          }
        "
        @delete="
          x => {
            deleteImg(x);
          }
        "
        @close="
          () => {
            editDialogCallBack && editDialogCallBack(null);
            editDialog = false;
          }
        "
      />
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getAdminPreference,
  getPaymentPreference,
  getUserPreference,
  getPrintOrderReceiptPreference,
  updateAdminPreference,
  updatePaymentPreference,
  updateUserPreference,
  updatePrintOrderReceiptPreference,
} from '@/api/systemConfig';
import api from '@/api';
import { mapActions, mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { get } from 'lodash';
import moment from 'moment';
import AddPhotoCard from '@/components/SystemConfig/AddPhotoCard';
import { deleteAttachment, uploadSingleAttachment } from '@/api/attachment';
import { getId } from '@/services/utils';
import { attnThumbHref } from '@/services/filters';
import ImgWithPlaceholder from '@/components/imgWithPlaceholder';
import ColorOptionsCard from '@/components/SystemConfig/ColorOptionsCard';
import { time } from '@/data/optionsConstants';
import CooperateCalendarEvent from '@/components/CooperateCalendarEvent/CooperateCalendarEvent.vue';

export default {
  name: 'SystemConfig',
  components: {
    ColorOptionsCard,
    ImgWithPlaceholder,
    CustomLabel,
    AddPhotoCard,
    time,
    CooperateCalendarEvent,
  },
  data() {
    return {
      time,
      editItem: null,
      userPreference: null,
      paymentPreference: null,
      adminPreference: null,
      printOrderReceiptPreference: null,
      editDialog: false,
      editDialogCallBack: null,
      newLabel: null,
      newImg: null,
      scalpCondition: null,
      workDone: null,
      volume: null,
      condition: null,
      expectedColor: null,
      type: null,
      generateUUIDloading: false,
    };
  },
  computed: {
    ...mapGetters(['debug']),
    localStart: {
      get() {
        const start = get(this.userPreference, 'openingHours.start');
        return moment().startOf('day').add(start, 'ms').format('HH:mm');
      },
      set(v) {
        const out = +moment(`1970-01-01T${v}:00+00:00`);
        this.$set(this.userPreference.openingHours, 'start', out);
      },
    },
    localEnd: {
      get() {
        const start = get(this.userPreference, 'openingHours.end');
        return moment().startOf('day').add(start, 'ms').format('HH:mm');
      },
      set(v) {
        const out = +moment(`1970-01-01T${v}:00+00:00`);
        this.$set(this.userPreference.openingHours, 'end', out);
      },
    },
  },
  async mounted() {
    await this.reload();
  },
  methods: {
    get,
    ...mapActions('alert', ['updateAlertMessage']),
    attnThumbHref,
    async openAndUpload(type, item = null, i = null) {
      this.type = type;
      this.editItem = item;
      const aaa = await this.openEditDialog();
      if (aaa && type === 'colorOptions') return await this.uploadObject(aaa, type, i);
      if (aaa) {
        await this.uploadImg(aaa, type, i);
      }
    },

    addScalpConditionLabel() {
      if (this.scalpCondition === null) {
        alert('Please enter content of content remark');
      } else {
        this.adminPreference.scalpCondition.push(this.scalpCondition);
        this.scalpCondition = null;
      }
    },

    openEditDialog() {
      return new Promise(cb => {
        this.editDialogCallBack = cb;
        this.editDialog = true;
      });
    },
    async reload() {
      [this.userPreference, this.printOrderReceiptPreference] = await Promise.all([
        getUserPreference(api),
        getPrintOrderReceiptPreference(api),
        // getPaymentPreference(api),
        // getAdminPreference(api),
      ]);
      this.userPreference.defaultBookingDuration /= 60 * 1000;
    },
    async uploadImg(obj, type, i = null) {
      let attachments;
      if (obj.img instanceof File) {
        attachments = await uploadSingleAttachment(api, obj.img);
      } else {
        attachments = obj.img;
      }
      if (type === 'workDone') {
        if (typeof i === 'number') {
          this.adminPreference.workDone.splice(i, 1, {
            img: attachments,
            label: obj.label,
          });
        } else {
          this.adminPreference.workDone.push({
            img: getId(attachments),
            label: obj.label,
          });
        }
      } else if (type === 'expectedColor') {
        if (typeof i === 'number') {
          this.adminPreference.expectedColor.splice(i, 1, {
            img: attachments,
            label: obj.label,
          });
        } else {
          this.adminPreference.expectedColor.push({
            img: getId(attachments),
            label: obj.label,
          });
        }
      } else if (type === 'hairConditionReferencePhoto') {
        if (typeof i === 'number') {
          this.adminPreference.hairConditionReferencePhoto.splice(i, 1, {
            img: attachments,
            label: obj.label,
          });
        } else {
          this.adminPreference.hairConditionReferencePhoto.push({
            img: getId(attachments),
            label: obj.label,
          });
        }
      }
      await updateAdminPreference(api, this.adminPreference);
    },
    async uploadObject(obj, type, i = null) {
      if (type === 'colorOptions') {
        if (typeof i === 'number') {
          this.adminPreference.colorOptions.splice(i, 1, {
            description: obj.description,
            label: obj.label,
            value: obj.value,
          });
        } else {
          this.adminPreference.colorOptions.push({
            description: obj.description,
            label: obj.label,
            value: obj.value,
          });
        }
      }
      await updateAdminPreference(api, this.adminPreference);
    },
    // async fileToBase64(img) {
    //   return new Promise((resolve, err) => {
    //     const reader = new FileReader();
    //     reader.addEventListener(
    //       'load',
    //       function () {
    //         resolve(reader.result.split(',')[1]);
    //       },
    //       false,
    //     );
    //     reader.readAsDataURL(img);
    //   });
    // },
    async updateUserPreference() {
      try {
        await updateUserPreference(api, {
          ...this.userPreference,
          defaultBookingDuration:
            parseFloat(this.userPreference.defaultBookingDuration) * 60 * 1000,
        });
        await this.updateAlertMessage({
          msg: 'Updated',
          type: 'success',
          color: 'success',
        });
      } catch (e) {
        alert(e.message);
      }
    },
    async updatePrintOrderReceiptPreference() {
      try {
        await updatePrintOrderReceiptPreference(api, this.printOrderReceiptPreference);
        await this.updateAlertMessage({
          msg: 'Updated',
          type: 'success',
          color: 'success',
        });
      } catch (e) {
        alert(e.message);
      }
    },

    async updateAdminPreference() {
      try {
        await updateAdminPreference(api, this.adminPreference);
        await this.updateAlertMessage({
          msg: 'Updated',
          type: 'success',
          color: 'success',
        });
      } catch (e) {
        alert(e.message);
      }
    },
    async updatePaymentPreference() {
      try {
        const data = this.paymentPreference;
        await updatePaymentPreference(api, data);
        await this.updateAlertMessage({
          msg: 'Updated',
          type: 'success',
          color: 'success',
        });
      } catch (e) {
        alert(e.message);
      }
    },
    async deleteImg(item) {
      await deleteAttachment(api, item.img);
      item.img = null;
      if (this.editDialogCallBack) await this.editDialogCallBack(item);
      await this.updateAlertMessage({
        msg: 'Image deleted',
        type: 'success',
        color: 'success',
      });
    },
    async goToGenerateUUID() {
      this.generateUUIDloading = true;
      this.$store.dispatch('alert/updateAlertMessage', {
        msg: '下載中',
        type: 'success',
        color: 'success',
      });
      window.location.href = api.defaults.baseURL + `/toca/admin/handBandUUIDs/generate`;
      setTimeout(() => (this.generateUUIDloading = false), 3000);
    },
  },
};
</script>

<style scoped>
textarea {
  font-family: monospace;
  background-color: #fafafa;
  width: 100%;
  min-height: 300px;
}
</style>
