var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"ClockIn Type"}}),_c('v-radio-group',{attrs:{"mandatory":"","row":""},model:{value:(_vm.model.clockInType),callback:function ($$v) {_vm.$set(_vm.model, "clockInType", $$v)},expression:"model.clockInType"}},[_c('v-radio',{staticClass:"mr-16",attrs:{"label":"ClockIn","value":"clockIn"}}),_c('v-radio',{staticClass:"mr-16",attrs:{"label":"ClockOut","value":"clockOut"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Staff"}}),_c('ValidationProvider',{attrs:{"name":"Staff","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete',{attrs:{"label":"Staff","path":'toca/admin/Profiles',"params":{
              sort: { created_at: -1 },
              filter: { status: _vm.ProfileStatus.accepted, role: { $ne: _vm.roles.client } },
            },"item-text":"name","item-value":"_id","error-messages":errors,"clearable":true,"has-items-slot":true},scopedSlots:_vm._u([{key:"items",fn:function(content){return [_c('span',[_vm._v(_vm._s(_vm.get(content, 'item.name', '') + ' ' + _vm.displayPhone(_vm.get(content, 'item.phone', ''))))])]}}],null,true),model:{value:(_vm.model.staff),callback:function ($$v) {_vm.$set(_vm.model, "staff", $$v)},expression:"model.staff"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}}),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Date"}}),_c('ValidationProvider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('DatePickerWrapperInputMenu',{attrs:{"outlined":"","error-messages":errors},model:{value:(_vm.clockInOutDate),callback:function ($$v) {_vm.clockInOutDate=$$v},expression:"clockInOutDate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Time"}}),_c('ValidationProvider',{attrs:{"name":"Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('TimePickerWrapperInputMenu',{attrs:{"outlined":"","error-messages":errors},model:{value:(_vm.clockInOutTime),callback:function ($$v) {_vm.clockInOutTime=$$v},expression:"clockInOutTime"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }