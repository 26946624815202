<template>
  <v-card>
    <v-card-title style="align-items: center">
      Add Color Options
      <v-spacer />
      <v-btn
        icon
        @click="
          () => {
            $emit('close');
          }
        "
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text style="margin-bottom: -50px">
      <v-row>
        <v-col cols="7">
          <v-text-field outlined label="Color" v-model="label" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7">
          <v-text-field outlined label="Color Code" v-model="computedColorInput" />
          <!--          <v-color-picker v-model="value" mode="hex" />-->
          <v-color-picker v-model="computedColorInput" mode="hex" />
        </v-col>
      </v-row>
      <!--      <v-row>-->
      <!--        <v-col cols="4">-->
      <!--          <v-text-field outlined label="Full name" v-model="description" />-->
      <!--        </v-col>-->
      <!--      </v-row>-->
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="
          () => {
            $emit('add', { label: label, value: value, description: description });
          }
        "
        :disabled="!label && !item"
        >Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'colorOptionsCard',
  props: {
    item: {},
  },
  data() {
    return {
      label: null,
      value: '#000000',
      description: null,
      localValue: '#000000',
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(v) {
        if (v) {
          this.label = v.label;
          this.value = v.value;
          this.localValue = v.value;
          this.description = v.description;
        }
      },
    },
  },
  computed: {
    computedColorInput: {
      get() {
        return this.localValue;
      },
      set(val) {
        const regex = /#[a-fA-F0-9]{6}/;
        if (!regex.test(val)) return;
        this.value = val;
        this.localValue = val;
      },
    },
  },
};
</script>

<style scoped></style>
