<template>
  <div class="d-flex align-baseline">
    <div>{{ name || '-' }}</div>
    <v-btn v-show="id" class="ml-1" small @click.stop="goToPage" icon>
      <v-icon small color="primary"> mdi-open-in-new</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { getOid } from '@/services/utils';
import { get } from 'lodash';
export default {
  name: 'ShortCutTag',
  props: {
    id: String,
    name: String,
    route: String,
  },
  methods: {
    get,
    goToPage() {
      const router = this.$router.resolve({
        name: this.route,
        params: { id: this.id },
      });
      window.open(router.href, `${this.route}-${this.id}`);
    },
  },
};
</script>

<style lang="scss" scoped></style>
