<template>
  <div>
    <v-container>
      <div><h2>Transaction Details</h2></div>
      <v-divider class="mb-5" />
      <div v-if="details">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <v-row>
            <v-col cols="6">
              <custom-label item-field-name="Date" />
              <ValidationProvider v-slot="{ errors }" name="Date" rules="required">
                <v-text-field
                  outlined
                  readonly
                  v-model="computedDate"
                  label="Date"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="3">
              <custom-label item-field-name="Type" />
              <ValidationProvider v-slot="{ errors }" name="Type" rules="required">
                <v-text-field
                  outlined
                  readonly
                  v-model="computedType"
                  label="Type"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="3">
              <div v-if="details.metadata.CUSTOM.expenseType">
                <custom-label item-field-name="ExpenseType" />
                <ValidationProvider v-slot="{ errors }" name="ExpenseType" rules="required">
                  <v-text-field
                    outlined
                    readonly
                    v-model="details.metadata.CUSTOM.expenseType"
                    label="ExpenseType"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </div>
            </v-col>
            <v-col cols="6">
              <custom-label item-field-name="Amount" />
              <ValidationProvider v-slot="{ errors }" name="Amount" rules="required">
                <v-text-field
                  outlined
                  readonly
                  v-model="computedAmount"
                  label="Amount"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="6">
              <custom-label item-field-name="PaymentMethod" />
              <ValidationProvider v-slot="{ errors }" name="PaymentMethod" rules="required">
                <v-text-field
                  outlined
                  readonly
                  v-model="details.metadata.CUSTOM.paymentMethod"
                  label="PaymentMethod"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="6">
              <custom-label item-field-name="Remarks" />
              <ValidationProvider v-slot="{ errors }" name="Remarks" rules="required">
                <v-text-field
                  outlined
                  readonly
                  v-model="details.metadata.CUSTOM.remarks"
                  label="Remarks"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="6">
              <template v-if="get(order, '_id', false)">
                <custom-label item-field-name="Order" />
                <ShortCutTag :id="order._id" :name="order.sid" route="physicalProductOrder" />
              </template>
            </v-col>
            <v-col cols="6" v-if="details.metadata.CUSTOM.attachments[0]">
              <custom-label item-field-name="Attachment (Images)" />
              <v-carousel>
                <v-carousel-item
                  v-for="(item, i) in details.metadata.CUSTOM.attachments"
                  :key="i"
                  :src="getThumbnail(api, item, 'hd')"
                  contain
                  @click.stop="
                    () => {
                      currentImage = getThumbnail(api, item, 'hd');
                      displayImage = true;
                    }
                  "
                />
              </v-carousel>
            </v-col>
          </v-row>
        </ValidationObserver>
      </div>
      <div v-else class="d-flex justify-center" style="padding-top: 20%">
        <h2>No Record to found</h2>
      </div>
    </v-container>
    <v-dialog v-model="displayImage" width="fit-content">
      <v-card>
        <v-img height="90vh" contain :src="currentImage" @click="displayImage = false" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';

import { crudCreate, crudRead, crudUpdate, crudList } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import moment from 'moment';
import { getThumbnail, toThousandFilter } from '@/services/filters';
import ShortCutTag from '@/components/ShortCutTag.vue';
import { get } from 'lodash';

export default {
  name: 'TransactionDetails',
  components: { CustomLabel, ShortCutTag },
  mixins: [],
  props: {
    id: '',
  },
  data() {
    return {
      api,
      details: null,
      order: null,
      data: [],
      search: '',
      displayWarehouseList: false,
      currentImage: null,
      displayImage: false,
    };
  },
  mounted() {
    this.loadStore();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin', 'isMaster']),
    isNew() {
      return this.id === 'new';
    },
    getId() {
      return this.id;
    },
    computedDate: {
      get() {
        return moment(this.details?.metadata?.CUSTOM?.claimCreatedAt).format('YYYY/MM/DD');
      },
      set(v) {
        this.details.metadata.CUSTOM.claimCreatedAt = v;
      },
    },
    computedAmount: {
      get() {
        return `$ ${toThousandFilter(-Number(this.details?.amount?.value))}`;
      },
      set(v) {
        this.details.amount.value = v;
      },
    },
    computedType: {
      get() {
        return this.details?.amount?.value > 0 ? 'Expense' : 'Income';
      },
      set(v) {
        this.details.metadata.CUSTOM.type = v;
      },
    },
  },
  methods: {
    moment,
    get,
    getThumbnail,
    async loadStore() {
      if (this.isNew) {
        this.details = {};
        this.order = {};
      } else {
        this.details = await crudRead(api, RESTFUL.transaction.read, this.id);
        this.order = await crudList(api, RESTFUL.physicalProductOrders.list, {
          filter: { posOrderRef: this.details.metadata.CUSTOM.orderRef || 'null' },
          select: ['_id', 'sid'],
          limit: 1,
        }).then(res => res?.docs?.[0]);
        // this.warehouseDocs = await getWarehousesByStoreId(api, this.id);
        // console.log(this.warehouseDocs, 'wrong data');
      }

      // this.warehouseDocs?.sort((a, b) => a?.tableNo - b?.tableNo);
    },
    async save() {
      try {
        if (!(await this.$refs.form?.validate?.())) {
          return;
        }
        const model = this.isNew
          ? await crudCreate(api, RESTFUL.transaction.create, this.details)
          : await crudUpdate(api, RESTFUL.transaction.update, this.id, this.details);

        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
