<template>
  <ValidationObserver ref="form" v-slot="{ invalid }">
    <v-card class="d-flex align-center flex-wrap pa-4 flex-column flex-sm-row" style="gap: 15px">
      <h3>Add New:</h3>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <ValidationProvider name="Date" v-slot="{ errors }" :rules="{ required: true }">
            <v-text-field
              style="max-width: 200px"
              label="Date"
              dense
              outlined
              hide-details
              v-model="model.date"
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </template>
        <v-date-picker v-model="model.date" @change="menu = false" />
      </v-menu>
      <ValidationProvider name="Event Name" v-slot="{ errors }" :rules="{ required: true }">
        <v-text-field
          style="max-width: 200px"
          label="Event Name"
          v-model="model.eventName"
          hide-details
          outlined
          dense
          :error-messages="errors"
        />
      </ValidationProvider>

      <ValidationProvider name="Remarks" v-slot="{ errors }" :rules="{ required: false }">
        <v-text-field
          label="Remarks"
          v-model="model.remarks"
          hide-details
          outlined
          dense
          :error-messages="errors"
        />
      </ValidationProvider>
      <v-btn color="primary" @click="addItem">Add</v-btn>
    </v-card>
  </ValidationObserver>
</template>

<script>
import { RESTFUL, calendarEventType } from '@/data/constants';

import { crudCreate } from '@/api/crud';
import api from '@/api';

export default {
  data() {
    return {
      menu: false,
      // TODO: change to not hard code
      model: null,
    };
  },
  beforeMount() {
    this.resetModel();
  },
  methods: {
    resetModel() {
      this.model = { eventType: calendarEventType.holiday };
    },
    async addItem() {
      if (!(await this.$refs.form?.validate?.())) {
        return;
      }
      const model = await crudCreate(api, RESTFUL.cooperateCalendarEvents.create, this.model);
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Saved',
        type: 'success',
        color: 'success',
      });
      this.$emit('create', model);
      this.resetModel();
      this.$refs.form?.reset?.();
    },
  },
};
</script>

<style lang="scss" scoped></style>
