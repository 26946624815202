<template>
  <ListView
    title="Suppliers"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :enable-create="true"
    :search.sync="search"
    :page.sync="page"
    :data="data"
    :header="header"
  />
</template>

<script>
import ListView from '@/components/ListView';
import { roles } from '@/data/constants';
import { mapGetters } from 'vuex';
import { getStores } from '@/api/store';
import api from '@/api/';
import { crudList } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';

export default {
  name: 'Supplier',
  data() {
    return {
      header: [{ text: 'Name', value: 'name' }],
      data: [],
      search: '',
    };
  },
  mixins: [listViewPageMixin],
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin']),

    checkRole() {
      return this.role !== roles.master;
    },
    restfulURL() {
      return RESTFUL.suppliers.list;
    },
  },

  methods: {
    actionCreate() {
      this.$router.push({ name: 'supplier', params: { id: 'new' } });
    },
    actionDetails(item) {
      this.$router.push({ name: 'supplier', params: { id: item?._id } });
    },
  },
};
</script>
