<template>
  <v-row>
    <v-col>
      <v-menu
        ref="menu"
        v-model="fromDateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            outlined
            readonly
            :label="label"
            :value="fromDateDisp"
            v-on="on"
            prepend-inner-icon="mdi-clock"
            @click:prepend-inner="fromDateMenu = true"
          />
        </template>
        <v-time-picker
          :disabled="this.disableTimePicker"
          :min="minDate"
          :max="maxDate"
          v-model="fromTimeVal"
          @click:minute="$refs.menu.save(fromTimeVal)"
        ></v-time-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'TimePickerWrapperInputMenu',
  props: {
    value: {},
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
    events: [],
    eventColor: {
      type: String,
      default: 'primary',
    },
    label: {},
    disableTimePicker: { default: false },
  },
  data() {
    return {
      fromDateMenu: false,
    };
  },
  computed: {
    fromTimeVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    fromDateDisp() {
      return this.fromTimeVal ? this.fromTimeVal : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.filled-color {
  background-color: red;
}

.v-text-field {
  margin-bottom: -24px;
}
</style>
