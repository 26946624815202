var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('div',[_c('h2',[_vm._v("Transaction Details")])]),_c('v-divider',{staticClass:"mb-5"}),(_vm.details)?_c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Date"}}),_c('ValidationProvider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","readonly":"","label":"Date","error":!!errors.length,"error-messages":errors},model:{value:(_vm.computedDate),callback:function ($$v) {_vm.computedDate=$$v},expression:"computedDate"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('custom-label',{attrs:{"item-field-name":"Type"}}),_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","readonly":"","label":"Type","error":!!errors.length,"error-messages":errors},model:{value:(_vm.computedType),callback:function ($$v) {_vm.computedType=$$v},expression:"computedType"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.details.metadata.CUSTOM.expenseType)?_c('div',[_c('custom-label',{attrs:{"item-field-name":"ExpenseType"}}),_c('ValidationProvider',{attrs:{"name":"ExpenseType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","readonly":"","label":"ExpenseType","error":!!errors.length,"error-messages":errors},model:{value:(_vm.details.metadata.CUSTOM.expenseType),callback:function ($$v) {_vm.$set(_vm.details.metadata.CUSTOM, "expenseType", $$v)},expression:"details.metadata.CUSTOM.expenseType"}})]}}],null,true)})],1):_vm._e()]),_c('v-col',{attrs:{"cols":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Amount"}}),_c('ValidationProvider',{attrs:{"name":"Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","readonly":"","label":"Amount","error":!!errors.length,"error-messages":errors},model:{value:(_vm.computedAmount),callback:function ($$v) {_vm.computedAmount=$$v},expression:"computedAmount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('custom-label',{attrs:{"item-field-name":"PaymentMethod"}}),_c('ValidationProvider',{attrs:{"name":"PaymentMethod","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","readonly":"","label":"PaymentMethod","error":!!errors.length,"error-messages":errors},model:{value:(_vm.details.metadata.CUSTOM.paymentMethod),callback:function ($$v) {_vm.$set(_vm.details.metadata.CUSTOM, "paymentMethod", $$v)},expression:"details.metadata.CUSTOM.paymentMethod"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Remarks"}}),_c('ValidationProvider',{attrs:{"name":"Remarks","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","readonly":"","label":"Remarks","error":!!errors.length,"error-messages":errors},model:{value:(_vm.details.metadata.CUSTOM.remarks),callback:function ($$v) {_vm.$set(_vm.details.metadata.CUSTOM, "remarks", $$v)},expression:"details.metadata.CUSTOM.remarks"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.get(_vm.order, '_id', false))?[_c('custom-label',{attrs:{"item-field-name":"Order"}}),_c('ShortCutTag',{attrs:{"id":_vm.order._id,"name":_vm.order.sid,"route":"physicalProductOrder"}})]:_vm._e()],2),(_vm.details.metadata.CUSTOM.attachments[0])?_c('v-col',{attrs:{"cols":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Attachment (Images)"}}),_c('v-carousel',_vm._l((_vm.details.metadata.CUSTOM.attachments),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":_vm.getThumbnail(_vm.api, item, 'hd'),"contain":""},on:{"click":function($event){$event.stopPropagation();return (function () {
                    _vm.currentImage = _vm.getThumbnail(_vm.api, item, 'hd');
                    _vm.displayImage = true;
                  }).apply(null, arguments)}}})}),1)],1):_vm._e()],1)]}}],null,false,595975512)})],1):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"padding-top":"20%"}},[_c('h2',[_vm._v("No Record to found")])])],1),_c('v-dialog',{attrs:{"width":"fit-content"},model:{value:(_vm.displayImage),callback:function ($$v) {_vm.displayImage=$$v},expression:"displayImage"}},[_c('v-card',[_c('v-img',{attrs:{"height":"90vh","contain":"","src":_vm.currentImage},on:{"click":function($event){_vm.displayImage = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }