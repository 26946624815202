<template>
  <ListView
    title="Transaction"
    @reload="reload"
    @navDetails="actionDetails"
    @update:limit="limit = $event"
    enable-select-items-per-page
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :header="header"
    :is-fluid="true"
  >
    <!-- <template v-slot:filter>
         <v-btn-toggle v-model="filterType" color="primary" mandatory tile>
           <v-btn value="All">All</v-btn>
           <v-btn
             v-for="item in computedTransactionOption"
             :value="item.value"
             :key="`tab_${item.value}`"
             >{{ item.text }}
           </v-btn>
         </v-btn-toggle>
       </template> -->

    <!-- <template v-slot:item.transactionTime="{ item }">
      {{ item.created_at | time }}
    </template> -->
    <template v-slot:action>
      <v-btn text @click="reload">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-btn
        class="mx-4"
        @click="exportSalesRecord"
        :disabled="!filterStore"
        :loading="exportCSVLoadingRef"
      >
        Export Product sales records
      </v-btn>
      <v-btn
        @click="exportTransactionRecord"
        :disabled="!filterStore"
        :loading="exportCSVLoadingRef"
      >
        Export transaction records
      </v-btn>
    </template>
    <template #filter>
      <v-row>
        <v-col cols="3">
          <autocomplete
            label="Store"
            :path="'toca/admin/stores'"
            :params="{
              sort: { name: 1 },
              select: ['name', 'sid'],
            }"
            item-text="name"
            item-value="_id"
            v-model="filterStore"
            :clearable="true"
          />
          <div style="color: red; font-size: 12px; margin-top: -25px">
            Please select one store for export report
          </div>
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="posTransactionType"
            label="Transaction Tpye"
            :items="POSTransactionTypesOptions"
            item-text="text"
            item-value="value"
            outlined
            clearable
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="paymentMethod"
            label="Payment Method"
            :items="POSpaymentMethodsOptions"
            item-text="text"
            item-value="value"
            outlined
            clearable
          />
        </v-col>
        <v-col cols="3"><DateRangePicker v-model="searchTime" /></v-col>
      </v-row>
    </template>

    <!-- 
    <template v-slot:item.mode="{ item }">
      {{ item.mode }}
    </template>
    <template v-slot:item.transactionType="{ item }">
      {{ item.transactionType }}
    </template>
    <template v-slot:item.status="{ item }">
      {{ item.status }}
    </template> -->
    <template v-slot:item.createdAt="{ item }">
      {{ moment(item.createdAt).format('YYYY-MM-DD HH:mm') }}
    </template>
    <template v-slot:item.amount="{ item }">
      $ {{ get(item, 'amount.value.$numberDecimal', '') }}
    </template>
    <template v-slot:item.details="{ item: { details } }">
      <template v-if="details.serialNumber">
        <p>Terminal</p>
        <p>{{ details.serialNumber }}</p>
      </template>
      <p>
        {{ 'Discount : ' + details.discount }}
      </p>
    </template>
    <template v-slot:item.orderType="{ item }">
      {{ get(item, 'ref.orderType', '-') }}
    </template>
  </ListView>
</template>

<script>
import { bookingSortOption, transactionOption, month, years } from '@/data/optionsConstants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { mapActions, mapGetters } from 'vuex';
import { RESTFUL, transactionTypes } from '@/data/constants';
import api from '@/api';
import moment from 'moment';
import { voidBooking } from '@/api/bookings';
import { get, merge } from 'lodash';
import { capitalize, getBookingIdFromTransactionRef } from '@/services/filters';
import DateRangePicker from '@/components/DateRangePicker';
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import { POSpaymentMethodsOptions, POSTransactionTypesOptions } from '@/data/optionsConstants';
import { getUserListByProfileId, listProfiles } from '@/api/auth/membership';

export default {
  name: 'transactions',
  components: {
    DateRangePicker,
    Autocomplete,
  },
  data() {
    return {
      transactionTypes,
      transactionOption,
      POSpaymentMethodsOptions,
      populate: [
        'owner',
        {
          path: 'metadata.TicketsPackage.commission.to',
          model: 'MasterProfile',
          select: 'name',
        },
        {
          path: 'metadata.PhysicalProductOrder.items.[0].commission.to',
          model: 'MasterProfile',
          select: 'name',
        },
      ],
      sort: { created_at: -1 },
      exportDialog: false,
      qrcodeDialog: false,
      filterStatus: null,
      bookingSortOption,
      month,
      years,
      exportYear: null,
      exportMonth: null,
      downloadLink: null,
      filterType: 'All',
      searchTime: {
        start: moment().startOf('d').format('YYYY-MM-DD'),
        end: moment().startOf('d').format('YYYY-MM-DD'),
      },
      filterStore: null,
      paymentMethod: null,
      profiles: [],
      exportCSVLoadingRef: false,
      POSTransactionTypesOptions,
      posTransactionType: null,
    };
  },
  mixins: [listViewPageMixin],
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin']),
    restfulURL: () => {
      const posConfigUrl = `${process.env.VUE_APP_POS_GATEWAY}`;
      return posConfigUrl + `/transactions/admin-panel`;
    },
    computedYear() {
      return this.years.filter(x => moment().year() >= Number(x));
    },
    filter() {
      let out = {};
      out['qWorkspace'] = '2ac705576a2e';
      out['sort'] = '-createdAt';

      if (this.searchTime.start) {
        out['startDate'] = new Date(
          moment(this.searchTime.start).startOf('day').toDate(),
        ).toUTCString();
      }

      if (this.searchTime.end) {
        out['endDate'] = new Date(moment(this.searchTime.end).endOf('day').toDate()).toUTCString();
      }

      if (this.filterStore) {
        out['qStore'] = this.filterStore;
      }

      if (this.paymentMethod) {
        out['paymentType'] = this.paymentMethod;
      }
      if (this.posTransactionType) {
        out['transactionType'] = this.posTransactionType;
      }
      return out;
    },
    // mergeParams() {
    //   return {};
    // },
    // computedTransactionOption() {
    //   return !this.isAdmin
    //     ? this.transactionOption.filter(x => x.value !== transactionTypes.PayrollRecord)
    //     : this.transactionOption;
    // },
    header() {
      const header = [
        { text: 'Date and Time', value: 'createdAt' },
        { text: 'Type', value: 'transactionType' },
        { text: 'Order Number', value: 'sid' },
        { text: 'Order Type', value: 'orderType' },
        { text: 'Client', value: 'ref.client.name' },
        { text: 'Payment Method', value: 'mode' },
        { text: 'Payment Status', value: 'status' },
        { text: 'Total', value: 'amount' },
        { text: 'Payment Details', value: 'details' },
      ];
      // const header = [
      //   { text: 'Transaction Date', value: 'transactionDate' },
      //   // { text: 'Transaction Time', value: 'transactionTime' },
      //   {
      //     text: 'Transaction Type',
      //     value: 'transactionType',
      //     show: this.filterType === 'All',
      //     // || this.filterType === transactionTypes.computedPurchaseInService,
      //   },
      //   {
      //     text: 'Payment Method',
      //     value: 'paymentMethod',
      //     // show: this.filterType !== transactionTypes.PayrollRecord,
      //   },
      //   { text: 'Transaction ID', value: 'sid' },
      //   {
      //     // text: this.filterType === transactionTypes.PayrollRecord ? 'Staff Name' : 'Customer Name',
      //     text: 'Customer Name',
      //     value: 'customerName',
      //   },
      //   {
      //     text: 'Customer Phone',
      //     // this.filterType === transactionTypes.PayrollRecord ? 'Staff Phone' : 'Customer Phone',
      //     value: 'customerPhone',
      //   },
      //   { text: 'Total Amount', value: 'amountPayable' },
      //   { text: 'Payment Status', value: 'paymentStatus' },
      //   // {
      //   //   text: 'Agent Name',
      //   //   value: 'metadata.TicketsPackage.commission.to.name',
      //   //   show: this.filterType === transactionTypes.TicketsPackage,
      //   // },
      //   {
      //     text: 'Agent Name',
      //     value: 'metadata.PhysicalProductOrder.items[0].commission.to.name',
      //     show: this.filterType === transactionTypes.PhysicalProductOrder,
      //   },
      //   { text: 'Booking ID', value: '_id', show: this.filterType === transactionTypes.Booking },
      // ];
      return header.filter(({ show }) => show === undefined || show);
    },
  },
  methods: {
    get,
    merge,
    moment,
    ...mapActions('alert', ['updateAlertMessage']),
    async load() {
      this.data = (
        await api.get(this.restfulURL, {
          params: merge(
            {
              page: this.page,
              search: this.search,
              limit: this.limit,
              filter: this.computedFilter,
              populate: this.populate,
              sort: this.sort,
            },
            this.mergeParams,
          ),
          headers: { Authorization: 'posfletrixlongtimetoken' },
        })
      ).data;
      this.data.docs = this.data.docs.map(x => x);
      // this.profiles = await listProfiles(api, { limit: 200 });
    },
    actionDetails(x) {
      // order
      if (x?.ref?._id) {
        this.$router.push({ name: 'orders', params: { id: x._id } });
      }
      // manual created transaction only
      // if (x && x.claimCreatedAt) {
      //   this.$router.push({ name: 'transaction', params: { id: x._id } });
      // }
    },
    getTransactionOptionByMetadata(metadata) {
      const transactionTypesValueArray = Object.values(transactionTypes);
      const itemTypeKey = Object.keys(metadata).find(key =>
        transactionTypesValueArray.includes(key),
      );
      if (itemTypeKey === transactionTypes.PhysicalProductOrder) {
        const isOnlineOrder = metadata?.PhysicalProductOrder?.items?.find(
          item => item?.commission?.amountPayable == null,
        );
        return {
          text: isOnlineOrder ? 'Online Order' : 'Physical Product Order (Offline Only)',
          value: transactionTypes.PhysicalProductOrder,
        };
      }
      if (metadata.CUSTOM) {
        return {
          text: 'Manually Created Record',
          value: transactionTypes.Custom,
        };
      }
      return transactionOption.find(({ value }) => value === itemTypeKey);
    },
    getPaymentMethodByMetadata(metadata) {
      const keys = Object.keys(metadata);
      return metadata?.[keys?.[0]]?.paymentMethod || '-';
    },
    async actionVoid(id) {
      if (!confirm('Do you confirm to void?')) return;
      try {
        await voidBooking(api, id);
        await this.updateAlertMessage({
          msg: 'Voided',
          type: 'success',
          color: 'success',
        });
      } catch (e) {
        alert('failed to void');
      }
      await this.reload();
    },
    async exportSalesRecord() {
      const startDate = moment(this.searchTime.start).startOf('day');
      const endDate = moment(this.searchTime.end).endOf('day');

      var duration = moment.duration(endDate.diff(startDate)); // TODO Needs to be handled in the backend
      var diffSeconds = duration.asSeconds();

      const limit = 40 * 24 * 60 * 60;
      if (diffSeconds < 0) {
        // throw new Error('Invalid date range');
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Invalid date range',
          type: 'error',
          color: 'error',
        });
        return;
      } else if (diffSeconds > limit) {
        // throw new Error('The analysis data is limited to 40 days');
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'The analysis data is limited to 40 day',
          type: 'error',
          color: 'error',
        });
        return;
      }

      try {
        this.exportCSVLoadingRef = true;
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Downloading',
          type: 'success',
          color: 'success',
        });

        const response = await api.get('/toca/admin/ProductOrder/exportSalesRecord', {
          responseType: 'blob',
          params: merge(
            {
              startDate: new Date(
                moment(this.searchTime.start).startOf('day').toDate(),
              ).toUTCString(),
              endDate: new Date(moment(this.searchTime.end).endOf('day').toDate()).toUTCString(),
              store: this.filterStore,
            },
            this.mergeParams,
          ),
        });
        if (response.status >= 400) {
          const res = await response.data.text();
          const errorObj = JSON.parse(res);
          const errorMessage = errorObj.message;
          throw errorMessage;
        } else {
          const startSting = moment(this.searchTime.start).startOf('day').format('YYYY-MM-DD');
          const endSting = moment(this.searchTime.end).startOf('day').format('YYYY-MM-DD');
          const fileName = `Sales report  ${startSting} to ${endSting}.csv`;

          const href = URL.createObjectURL(response.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', fileName);
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }
      } catch (error) {
        const message = 'Download failed, ' + error;
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: message,
          type: 'error',
          color: 'error',
        });
      } finally {
        this.exportCSVLoadingRef = false;
      }
    },
    async exportTransactionRecord() {
      const startDate = moment(this.searchTime.start).startOf('day');
      const endDate = moment(this.searchTime.end).endOf('day');

      var duration = moment.duration(endDate.diff(startDate)); // TODO Needs to be handled in the backend
      var diffSeconds = duration.asSeconds();
      const limit = 40 * 24 * 60 * 60;
      if (diffSeconds < 0) {
        // throw new Error('Invalid date range');
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Invalid date range',
          type: 'error',
          color: 'error',
        });
        return;
      } else if (diffSeconds > limit) {
        // throw new Error('The analysis data is limited to 40 days');
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'The analysis data is limited to 40 day',
          type: 'error',
          color: 'error',
        });
        return;
      }

      try {
        this.exportCSVLoadingRef = true;
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Downloading',
          type: 'success',
          color: 'success',
        });

        const response = await api.get('/toca/admin/transaction/export', {
          responseType: 'blob',
          params: merge(
            {
              startDate: new Date(
                moment(this.searchTime.start).startOf('day').toDate(),
              ).toUTCString(),
              endDate: new Date(moment(this.searchTime.end).endOf('day').toDate()).toUTCString(),
              store: this.filterStore,
              transactionType: this.posTransactionType,
              paymentType: this.paymentMethod,
            },
            this.mergeParams,
          ),
        });

        if (response.status >= 400) {
          const res = await response.data.text();
          const errorObj = JSON.parse(res);
          const errorMessage = errorObj.message;
          throw errorMessage;
        } else {
          const href = URL.createObjectURL(response.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'Transaction.csv');
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }
      } catch (error) {
        const message = 'Download failed, ' + error;
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: message,
          type: 'error',
          color: 'error',
        });
      } finally {
        this.exportCSVLoadingRef = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.selectedItem ::v-deep .v-select__selection--comma {
  margin-bottom: -25px;
}

.role {
  top: 10px;
  position: absolute;
  left: 24px;
  color: var(--v-success-base);
}
</style>
