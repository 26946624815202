/**
 * @param {AxiosInstance} api
 * @param {string} [search]
 * @return {Promise<string[]>}
 */
export async function listClockInRecords(api, options) {
  return (await api.get('toca/admin/clockInRecords/', { params: options })).data;
}

/**
 * @param {AxiosInstance} api
 * @return {Promise<void>}
 */
export async function createClockInRecord(api, data) {
  return (await api.post(`toca/admin/clockInRecords`, data)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @return {Promise<void>}
 */
export async function updateClockInRecord(api, id, data) {
  return (await api.patch(`toca/admin/clockInRecord/${id}`, data)).data;
}
