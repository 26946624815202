<template>
  <ListView
    title="Clock In & Clock Out Records"
    @reload="reload"
    @create="actionCreate"
    :enableCreate="true"
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :header="header"
  >
    <template v-slot:filterRight>
      <v-select
        v-model="selectedDateRange"
        :items="dateRangeOptions"
        item-text="title"
        item-value="value"
        label="Select Date"
        solo
        filled
      />
    </template>
    <template v-if="selectedDateRange === 'choose_the_date'" v-slot:filter>
      <v-row>
        <v-col cols="auto" class="d-flex" style="gap: 10px">
          <div class="d-flex align-center selectListCol">
            <v-menu
              v-model="startTimeDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedStart"
                  label="From"
                  prepend-icon="mdi-calendar"
                  style="min-width: max-content"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedStart"
                @input="startTimeDialog = false"
                header-color="primary"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <div class="d-flex align-center selectListCol">
            <v-menu
              v-model="endTimeDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedEnd"
                  label="From"
                  prepend-icon="mdi-calendar"
                  style="min-width: max-content"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                header-color="primary"
                v-model="selectedEnd"
                @input="endTimeDialog = false"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.time.clockIn="{ item }">
      {{ item.time.clockIn || '-' }}
    </template>
    <template v-slot:item.time.clockOut="{ item }">
      {{ item.time.clockOut || '-' }}
    </template>
    <!-- <template v-slot:item.created_at="{ item }">
      <span>{{ item.created_at | moment }}</span>
    </template> -->
    <!-- <template v-slot:item._late="{ item }">
      <span v-if="item._late">{{ item._late | transBoolean }}</span>
      <span v-else> - </span>
    </template>
    <template v-slot:item._earlyLeave="{ item }">
      <span v-if="item._earlyLeave">{{ item._earlyLeave | transBoolean }}</span>
      <span v-else> - </span>
    </template> -->
    <template v-slot:action="{ ctx }">
      <v-btn text @click="reload">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <!-- <v-btn v-if="isAdmin" icon color="primary">
        <v-icon
          @click="
            () => {
              qrcodeDialog = true;
            }
          "
          >mdi-qrcode-scan
        </v-icon>
      </v-btn> -->
      <v-btn v-if="isAdmin" icon color="primary" @click="addNewClockIn">
        <v-icon>mdi-plus-thick</v-icon>
      </v-btn>
    </template>
    <v-dialog :key="qrcodeDialog" v-model="qrcodeDialog" fullscreen>
      <v-card>
        <div class="d-flex">
          <v-spacer />
          <v-btn icon small @click="resetQRProps" class="ma-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="qr-reader-container">
          <div class="d-flex justify-center justify-sm-space-between mb-5 flex-wrap">
            <v-btn class="mt-5" :min-width="220" @click="clockInToggle" :disabled="clockIn">
              Check In
            </v-btn>
            <v-btn class="mt-5" :min-width="220" @click="clockInToggle" :disabled="!clockIn">
              Check Out
            </v-btn>
          </div>
          <div class="clock-in-wrapper">
            <div class="border-wrapper">
              <div class="qr-border">
                <div>
                  <!-- NOTE: Do not remove empty child for styling -->
                </div>
                <div :class="{ 'lds-qr': isLoading }"></div>
              </div>
            </div>
            <qrcode-stream @decode="onDecode" @init="onInit" class="clock-in-qr"></qrcode-stream>
          </div>
          <div v-if="Boolean(currentProfile)" class="d-flex flex-column">
            <div class="d-inline-flex align-center justify-center mt-5">
              <div
                class="pa-3 text-left white--text"
                style="background-color: green; border-radius: 16px; border-width: 0px"
              >
                <v-spacer />
                Master Name: {{ get(currentProfile, 'user.username', '') }}
                <v-spacer />
                Phone Number: {{ get(currentProfile, 'user.phone', '') }}
                <v-spacer />
                Entry Date: {{ date }}
              </div>
            </div>
            <div class="d-flex justify-center justify-sm-space-between mb-5 flex-wrap">
              <v-btn class="mt-5" :min-width="220" @click="onActiveConfirmation">
                Confirm {{ clockIn ? 'CheckIn' : 'CheckOut' }}
              </v-btn>
              <v-btn class="mt-5" :min-width="220" @click="() => (qrcodeDialog = false)">
                Cancel
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" max-width="600px">
      <v-card>
        <ClockInDetails v-model="model" @create="reload" />
      </v-card>
    </v-dialog>
  </ListView>
</template>

<script>
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { createClockInRecord } from '@/api/clockInRecords';
import { QrcodeStream } from 'vue-qrcode-reader';
import ClockInDetails from '@/components/ClockIn/ClockInDetails.vue';
import api from '@/api';
import { readProfile } from '../../api/auth/membership';
import moment from 'moment';
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import DateRangePicker from '@/components/DateRangePicker.vue';

export default {
  name: 'ClockInRecords',
  data() {
    return {
      header: [
        { text: 'Staff', value: 'staff.name' },
        { text: 'Staff ID', value: 'staff.sid' },
        { text: 'Date', value: 'date' },
        { text: 'ClockIn Time', value: 'time.clockIn' },
        { text: 'ClockOut Time', value: 'time.clockOut' },
      ],
      qrcodeDialog: false,
      editDialog: false,
      model: {},
      isLoading: false,
      clockIn: true,
      currentProfile: null,
      moment,
      populate: [{ path: 'staff' }, { path: 'created_by' }],
      startTimeDialog: false,
      endTimeDialog: false,
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
      dateRangeOptions: [
        { title: 'Today', value: 'today' },
        { title: 'Yesterday', value: 'yesterday' },
        { title: 'Last 7 Days', value: 'last_7_days' },
        { title: 'Last 1 Month', value: 'last_1_month' },
        { title: 'Choose the date', value: 'choose_the_date' },
      ],
      selectedDateRange: 'today',
    };
  },
  mixins: [listViewPageMixin],
  components: { QrcodeStream, ClockInDetails, DateRangePicker },
  computed: {
    ...mapGetters('auth', ['role']),
    // used in mixin
    restfulURL: () => RESTFUL.clockInRecords.list,
    date: {
      get() {
        return moment().format('DD/MM/YY');
      },
    },
    isMaster() {
      return this.role === 'toca.master';
    },
    isAdmin() {
      return this.role === 'admin';
    },
    isCashier() {
      return this.role === 'toca.cashier';
    },
    mergeParams() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
      };
    },
    selectedStart: {
      get() {
        if (this.startDate != null) return moment(this.startDate).format('YYYY-MM-DD');
        return null;
      },
      set(v) {
        this.startDate = moment(v).startOf('day').toDate();
      },
    },
    selectedEnd: {
      get() {
        if (this.endDate != null) return moment(this.endDate).format('YYYY-MM-DD');
        return null;
      },
      set(v) {
        this.endDate = moment(v).endOf('day').toDate();
      },
    },
  },
  watch: {
    selectedDateRange: {
      immediate: true,
      handler(v) {
        switch (v) {
          case 'today':
            this.getTimeRange(0);
            break;
          case 'yesterday':
            this.getTimeRange(1);
            break;
          case 'last_7_days':
            this.getTimeRange(7, true);

            break;
          case 'last_1_month':
            this.getTimeRange(31, true);
            break;
        }
      },
    },
    startDate() {
      this.page = 1;
      this.reload();
    },
    endDate() {
      this.page = 1;
      this.reload();
    },
    editDialog(v) {
      if (v) {
        this.model = {};
      }
    },
  },
  methods: {
    get,
    async clockInToggle() {
      this.clockIn = !this.clockIn;
    },
    async dispatchAlertMessage(msg, options) {
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg,
        ...options,
      });
    },
    async createClockInRecord(staffId) {
      let msg;
      try {
        await createClockInRecord(api, {
          staff: staffId,
          clockInType: this.clockIn ? 'clockIn' : 'clockOut',
        });
        msg = 'Record Created';
        // TODO: Check if update is ever required
        //   await updateClockInRecord(api, staffId, { staff: staffId, clockInType: 'clockOut' });
        //   msg = 'Record Updated';
        await this.dispatchAlertMessage(msg, {
          type: 'success',
          color: 'success',
        });
        this.editDialog = false;
        this.reload();
        // this.resetQRProps();
      } catch (err) {
        await this.dispatchAlertMessage(msg, {
          type: 'error',
          color: 'error',
        });
      }
    },
    resetQRProps() {
      this.qrcodeDialog = false;
      this.clockIn = true;
      this.currentProfile = null;
    },
    async onCancel() {
      this.resetQRProps();
    },
    async onActiveConfirmation() {
      await this.createClockInRecord(this.currentProfile);
      this.resetQRProps();
    },
    // TODO: DEFINE PROPERLY WHAT QR CODE DATA PARAMETERS ARE
    // Currently decoded profileId only
    async onDecode(profileId) {
      if (profileId) {
        const profile = await readProfile(api, profileId, {
          populate: [{ path: 'user' }],
        });
        this.currentProfile = profile;
      } else {
        console.log('invalid qr code profile');
      }
    },
    async onInit(promise) {
      console.log('on initiating');
      // show loading indicator
      this.isLoading = true;
      try {
        await promise;
        // successfully initialized
      } catch (error) {
        this.qrcodeDialog = false;
        let msg;
        if (error.name === 'NotAllowedError') {
          msg = 'user denied camera access permission';
        } else if (error.name === 'NotFoundError') {
          msg = 'no suitable camera device installed';
        } else if (error.name === 'NotSupportedError') {
          msg = 'page is not served over HTTPS (or localhost)';
        } else if (error.name === 'NotReadableError') {
          msg = 'maybe camera is already in use';
        } else if (error.name === 'OverconstrainedError') {
          msg = 'did you request the front camera although there is none?';
        } else if (error.name === 'StreamApiNotSupportedError') {
          msg = 'browser seems to be lacking features';
        }
        await this.dispatchAlertMessage(msg, {
          type: 'error',
          color: 'error',
        });
      } finally {
        // hide loading indicator
        this.isLoading = false;
      }
    },
    addNewClockIn() {
      this.editDialog = true;
    },
    getTimeRange(day, isRange) {
      this.startDate = moment().subtract(day, 'day').startOf('day').toDate();
      if (isRange) this.endDate = moment().subtract(1, 'day').endOf('day');
      else this.endDate = moment(this.startDate).endOf('day').toDate();
    },
  },
};
</script>

<style scoped>
.qr-reader-container {
  max-width: 600px;
  margin: 0 auto;
}

.clock-in-wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.clock-in-wrapper::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.clock-in-qr {
  display: flex;
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.border-wrapper {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  margin: 10px;
  z-index: 999;
}

.qr-border {
  position: relative;
  width: 100%;
  height: 100%;
  /* margin: 0 auto; */
}

.qr-border::before,
.qr-border::after,
.qr-border > :first-child::before,
.qr-border > :first-child::after {
  position: absolute;
  width: 40px;
  height: 40px;
  border-color: green; /* or whatever colour */
  border-style: solid; /* or whatever style */
  content: '';
}

.qr-border::before {
  top: 0;
  left: 0;
  border-width: 8px 0 0 8px;
}

.qr-border::after {
  top: 0;
  right: 0;
  border-width: 8px 8px 0 0;
}

.qr-border > :first-child::before {
  bottom: 0;
  right: 0;
  border-width: 0 8px 8px 0;
}

.qr-border > :first-child::after {
  bottom: 0;
  left: 0;
  border-width: 0 0 8px 8px;
}

.lds-qr {
  display: flex;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  padding-top: 40%;
}

.lds-qr:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-qr 1.2s linear infinite;
}

@keyframes lds-qr {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
