<template>
  <v-container>
    <div>
      <h2>{{ title }}</h2>
    </div>
    <v-divider class="mb-5" />

    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-card v-if="docs" flat>
        <!-- <v-row class="mr-3 ml-3">
          <v-col class="ml-auto" md="auto">
            <v-btn :disabled="invalid || isMaster" color="primary" @click="save">Save </v-btn>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <custom-label item-field-name="Warehouse" />
            <div>{{ docs.warehouse.name }}</div>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <custom-label item-field-name="Status" />
            <div>{{ docs.status | status }}</div>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <custom-label item-field-name="Handler" />
            <div>{{ docs.handleBy.name }}</div>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <custom-label item-field-name="Matched" />
            <div v-if="docs.status === stockCountRecordStatus.completed">
              <div v-if="docs._unmatched">
                <v-chip class="error my-2" label small>Unmatched</v-chip>
              </div>
              <div v-else>
                <v-chip class="success my-2" label small>Matched</v-chip>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <custom-label item-field-name="Start At" />
            <div>{{ docs.startAt | moment }}</div>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <custom-label item-field-name="End At" />
            <div>{{ docs.endAt | moment }}</div>
          </v-col>

          <v-col cols="12">
            <custom-label item-field-name="Product Count" />
            <div>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Sku</th>
                    <th>Barcode</th>
                    <th class="text-right" width="100px">Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item of identifiedProducts">
                    <th>{{ parseLocalizedString(item.product.name).en }}</th>
                    <th>{{ item.sku }}</th>
                    <th>{{ item.barcode }}</th>
                    <th class="text-right">{{ item.stock | toThousandFilter }}</th>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="text-right font-weight-bold">
                    <td colspan="3">Total</td>
                    <td>
                      {{ docs.identifiedTotalStock | toThousandFilter }}
                    </td>
                  </tr>
                </tfoot>
              </v-simple-table>
            </div>
          </v-col>
          <v-col cols="12">
            <custom-label item-field-name="Unidentified Product Count" />
            <div>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Sku</th>
                    <th>Barcode</th>
                    <th class="text-right" width="100px">Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item of unidentifiedProducts">
                    <th>{{ item.sku }}</th>
                    <th>{{ item.barcode }}</th>
                    <th class="text-right">{{ item.stock | toThousandFilter }}</th>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="text-right font-weight-bold">
                    <td colspan="2">Total</td>
                    <td>
                      {{ docs.unidentifiedTotalStock | toThousandFilter }}
                    </td>
                  </tr>
                </tfoot>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="docs.alignStockAt">
          <v-col>
            <custom-label item-field-name="Stock Alignment" />
            <div>
              <span class="mr-2">Action By:</span>
              <span class="font-weight-bold">{{ docs.alignStockBy.name }}</span>
            </div>
            <div>
              <span class="mr-2">Action At:</span>
              <span class="font-weight-bold">{{ docs.alignStockAt | moment }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </ValidationObserver>
  </v-container>
</template>

<script>
import api from '@/api';
import { crudCreate, crudRead, crudUpdate } from '@/api/crud';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { RESTFUL, stockCountRecordStatus } from '@/data/constants';
import { parseLocalizedString } from '@/services/utils';

export default {
  name: 'CountInventoryDetails',
  components: { CustomLabel },
  props: {
    id: {},
  },
  data() {
    return {
      stockCountRecordStatus,
      docs: null,
    };
  },
  mounted() {
    this.reload();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    isNew() {
      return this.id === 'new';
    },
    title() {
      return this.isNew ? 'New Count Inventory' : 'Count Inventory Details';
    },
    localizedName: {
      get() {
        return this.parseLocalizedString(this.docs?.name);
      },
      set(val) {
        this.docs.name = JSON.stringify(val);
      },
    },
    identifiedProducts() {
      return (this.docs?.productCounts || []).filter(({ product }) => product);
    },
    unidentifiedProducts() {
      return (this.docs?.productCounts || []).filter(({ product }) => !product);
    },
  },
  methods: {
    parseLocalizedString,
    async reload() {
      if (this.isNew) {
        this.docs = { discount: {} };
      } else {
        this.docs = await crudRead(api, RESTFUL.stockCountRecord.list, this.id, {
          populate: [
            'warehouse',
            'handleBy',
            'alignStockBy',
            'productCounts.product',
            'productStockDiff.product',
          ],
        });
      }
    },
    async save() {
      // try {
      //   if (!(await this.$refs.form?.validate?.())) {
      //     return;
      //   }
      //   const model = this.isNew
      //     ? await crudCreate(api, RESTFUL.vipProducts.create, this.docs)
      //     : await crudUpdate(api, RESTFUL.vipProducts.list, this.id, this.docs);
      //   await this.$store.dispatch('alert/updateAlertMessage', {
      //     msg: 'Saved',
      //     type: 'success',
      //     color: 'success',
      //   });
      //   if (this.isNew) {
      //     this.$router.replace({
      //       name: 'vipProduct',
      //       params: {
      //         id: model._id,
      //       },
      //     });
      //   }
      // } catch (e) {
      //   console.log(e);
      // }
    },
  },
};
</script>
