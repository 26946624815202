<template>
  <div>
    <v-container>
      <div>
        <h2>Purchase Order Details</h2>
      </div>
      <v-divider class="mb-5" />
      <div v-if="details">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <v-row class="mr-3 ml-3">
            <v-col class="ml-auto" md="auto">
              <!-- <v-btn class="mr-2" @click="print">Print</v-btn> -->
              <!-- <v-btn :disabled="invalid || isMaster" color="primary" @click="save">Save</v-btn> -->
              <v-btn :disabled="invalid || !isAdmin" color="primary" @click="saveStatus"
                >Save</v-btn
              >
            </v-col>
          </v-row>
          <v-col :cols="12">
            <v-card>
              <v-card-title class="font-weight-bold d-flex">
                <h4>Purchase Order Information</h4>
                <div class="ml-5">
                  <ValidationProvider v-slot="{ errors }" name="status" rules="required">
                    <v-select
                      outlined
                      v-model="details.status"
                      :items="purchaseOrderStatusOption"
                      :item-text="item => item.label"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </div>
              </v-card-title>
              <v-card-title>
                <v-row>
                  <v-col :cols="12" :md="6">
                    Store:
                    {{ get(details, 'store.name', '-') }}
                  </v-col>
                  <v-col :cols="12" :md="6">
                    Supplier:
                    {{ get(details, 'supplier.name', '-') }}
                  </v-col>
                  <v-col :cols="12" :md="6">
                    Order Date:
                    {{ get(details, 'created_at', '-') | moment('YYYY/MM/DD hh:mm:ss') }}
                  </v-col>
                  <v-col :cols="12" :md="6" class="d-flex align-baseline">
                    Created by:&nbsp;<ProfileNameTag :profile="details.createdBy.profiles[0]" />
                  </v-col>
                  <v-col :cols="12" :md="6">
                    Updated Date:
                    {{ get(details, 'updated_at', '-') | moment('YYYY/MM/DD hh:mm:ss') }}
                  </v-col>
                  <v-col :cols="12" :md="6" class="d-flex align-baseline" v-if="details.updatedBy">
                    Updated by:&nbsp;<ProfileNameTag :profile="details.updatedBy.profiles[0]" />
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col :cols="12">
            <v-card>
              <v-card-title class="font-weight-bold"
                >Purchase Order Product Information</v-card-title
              >
              <v-card-title>
                <template v-for="item in details.items">
                  <v-row>
                    <v-col cols="2">
                      <div v-if="item.product.featuredImage">
                        <v-img
                          :max-height="imageSize"
                          :max-width="imageSize"
                          :min-height="imageSize"
                          :min-width="imageSize"
                          :key="item.featuredImage"
                          :src="attnThumbHref(item.product.featuredImage, 128)"
                        ></v-img>
                      </div>
                      <div v-else>
                        <v-img
                          :max-height="imageSize"
                          :max-width="imageSize"
                          :min-height="imageSize"
                          :min-width="imageSize"
                          contain
                          src="@/assets/TocaLogo.svg"
                        />
                      </div>
                    </v-col>
                    <v-col cols="2">
                      {{ getProductName(item) }}
                    </v-col>
                    <v-col cols="2">
                      <ValidationProvider v-slot="{ errors }" name="Unit Price">
                        <v-text-field
                          outlined
                          v-model="item.unitPrice"
                          label="Unit Price"
                          disabled
                          :error="!!errors.length"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="2">
                      <ValidationProvider v-slot="{ errors }" name="Quantity">
                        <v-text-field
                          outlined
                          v-model="item.qty"
                          label="Quantity"
                          disabled
                          :error="!!errors.length"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="2">
                      <ValidationProvider v-slot="{ errors }" name="Received Quantity">
                        <v-text-field
                          outlined
                          v-model="item.totalPrice"
                          label="Total Price"
                          disabled
                          :error="!!errors.length"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      cols="2"
                      v-if="originalStatus == 'received' || originalStatus == 'completed'"
                    >
                      <ValidationProvider v-slot="{ errors }" name="Received Quantity">
                        <v-text-field
                          outlined
                          v-model="item.receivedQty"
                          label="Received Quantity"
                          disabled
                          :error="!!errors.length"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <!-- <v-col cols="2">
                      Updated Date:
                      {{ get(item, 'updated_at', '-') | moment('YYYY/MM/DD hh:mm:ss') }}
                    </v-col> -->
                  </v-row>
                </template>
              </v-card-title>
            </v-card>
          </v-col>
        </ValidationObserver>
      </div>
      <div v-else class="d-flex justify-center" style="padding-top: 20%">
        <h2>No Record to found</h2>
      </div>
    </v-container>
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { crudCreate, crudRead, crudUpdate } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import { get } from 'lodash';
import ProfileNameTag from '@/components/ProfileNameTag.vue';
import { attnThumbHref } from '@/services/filters';

export default {
  name: 'purchaseOrderDetails',
  components: { CustomLabel, ProfileNameTag },
  mixins: [],
  props: {
    id: '',
  },
  data() {
    return {
      details: null,
      data: [],
      imageSize: 100,
      originalStatus: 'completed',
    };
  },
  mounted() {
    this.reload();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin', 'isMaster']),
    isNew() {
      return this.id === 'new';
    },
    purchaseOrderStatusOption() {
      let statusOptions = [];
      const originalStatus = this.originalStatus;
      if (originalStatus == 'pending') {
        statusOptions.push({ label: 'Pending', value: 'pending' });
        statusOptions.push({ label: 'In Progress', value: 'in_progress' });
      } else if (originalStatus == 'in_progress') {
        statusOptions.push({ label: 'In Progress', value: 'in_progress' });
        statusOptions.push({ value: 'rejected', label: 'Rejected' });
      } else if (originalStatus == 'received') {
        statusOptions.push({ label: 'Received', value: 'received' });
        statusOptions.push({ value: 'completed', label: 'Completed' });
      } else if (originalStatus == 'rejected')
        statusOptions.push({ value: 'rejected', label: 'Rejected' });
      else if (originalStatus == 'completed')
        statusOptions.push({ value: 'completed', label: 'Completed' });
      else if (originalStatus == 'canceled')
        statusOptions.push({ value: 'canceled', label: 'Canceled' });

      return statusOptions;
    },
  },
  methods: {
    get,
    attnThumbHref,
    async reload() {
      if (this.isNew) {
        this.details = {};
      } else {
        this.details = await crudRead(api, RESTFUL.purchaseOrders.read, this.id);
        this.originalStatus = this.details.status;
      }
    },
    async save() {
      try {
        if (!(await this.$refs.form?.validate?.())) {
          return;
        }
        const model = this.isNew
          ? await crudCreate(api, RESTFUL.purchaseOrders.create, this.details)
          : await crudUpdate(api, RESTFUL.purchaseOrders.update, this.id, this.details);

        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        if (this.isNew) {
          this.$router.replace({
            name: 'purchase-order',
            params: {
              id: model._id,
            },
          });
        } else this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    getProductName(item) {
      const product = item.product;
      try {
        return (
          JSON?.parse(product.name)?.['zh-cn'] || JSON?.parse(product.name)?.en || product.name
        );
      } catch (error) {
        return product.name;
      }
    },
    async saveStatus() {
      try {
        await crudUpdate(api, RESTFUL.purchaseOrders.updateStatus, this.id, {
          status: this.details.status,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        this.reload();
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: error,
          type: 'error',
          color: 'error',
        });
        return;
      }
    },
  },
};
</script>
