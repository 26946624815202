<template>
  <div>
    <ListView
      title="Count inventory"
      @reload="reload"
      @navDetails="actionDetails"
      :search.sync="search"
      :data="data"
      :header="header"
      :enableCreate="false"
    >
      <template v-slot:item.startAt="{ item }">
        {{ item.startAt | moment }}
      </template>
      <template v-slot:item.endAt="{ item }">
        {{ item.endAt | moment }}
      </template>
      <template v-slot:item.unidentifiedTotalStock="{ item }">
        {{ item.unidentifiedTotalStock | toThousandFilter }}
      </template>
      <template v-slot:item.identifiedTotalStock="{ item }">
        {{ item.identifiedTotalStock | toThousandFilter }}
      </template>

      <template v-slot:item._match="{ item }">
        <div v-if="item.status === stockCountRecordStatus.completed">
          <div v-if="item._unmatched">
            <v-chip class="error my-2" label small>Unmatched</v-chip>
            <div class="caption">
              <div class="font-weight-bold">Stock Alignment:</div>
              <div><v-icon small>mdi-account</v-icon> {{ item.alignStockBy.name }}</div>
              <div><v-icon small>mdi-clock-outline</v-icon> {{ item.alignStockAt | moment }}</div>
            </div>
          </div>
          <div v-else>
            <v-chip class="success my-2" label small>Matched</v-chip>
          </div>
        </div>
      </template>
    </ListView>
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import ProductListDialog from '@/components/PhysicalProduct/ProductListDialog';
import ProductStoreDetailDialog from '@/components/ProductStore/ProductStoreDetailDialog';
import { RESTFUL, stockCountRecordStatus } from '@/data/constants';
import ListView from '@/components/ListView';
import listViewPageMixin from '@/services/listViewPageMixin';
import { getOid } from '@/services/utils';
import { parseLocalizedString } from '@/services/utils';

export default {
  name: 'CountInventories',
  components: { CustomLabel, ListView, ProductListDialog, ProductStoreDetailDialog },
  mixins: [listViewPageMixin],
  props: {
    warehouse: '',
  },
  data() {
    return {
      stockCountRecordStatus,
      details: null,
      header: [
        { text: 'Start', value: 'startAt' },
        { text: 'End', value: 'endAt' },
        { text: 'Warehouse', value: 'warehouse.name' },
        { text: 'Product Count', value: 'identifiedTotalStock' },
        { text: 'Unidentified Product Count', value: 'unidentifiedTotalStock' },
        { text: 'Status', value: 'status' },
        { text: 'Match', value: '_match' },
      ],
      data: [],
      search: '',
      sort: { startAt: -1 },
      populate: ['warehouse', 'handleBy', 'alignStockBy'],
    };
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin', 'isMaster']),
    restfulURL() {
      return RESTFUL.stockCountRecord.read;
    },
  },
  methods: {
    parseLocalizedString,
  },
};
</script>
