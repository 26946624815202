var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('h2',[_vm._v("System Config")])]),_c('v-divider',{staticClass:"mb-5"}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"my-3 mx-3",attrs:{"min-height":"56","color":"primary","loading":_vm.generateUUIDloading},on:{"click":_vm.goToGenerateUUID}},[_vm._v("Generate UUID ")])],1),(_vm.userPreference)?_c('v-card',[_c('ValidationObserver',{ref:"userPreferenceForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('v-card-title',[_vm._v("Operation Setting")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"rules":{ min_value: 1, numeric: true },"name":"Max Servicing Capacity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"label":"Max Servicing Capacity","min":"1","type":"number"},model:{value:(_vm.userPreference.maxServicingCapacity),callback:function ($$v) {_vm.$set(_vm.userPreference, "maxServicingCapacity", _vm._n($$v))},expression:"userPreference.maxServicingCapacity"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ min: 0, numeric: true },"name":"Feedback Award"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"label":"Feedback Award","type":"number"},model:{value:(_vm.userPreference.feedbackAward),callback:function ($$v) {_vm.$set(_vm.userPreference, "feedbackAward", _vm._n($$v))},expression:"userPreference.feedbackAward"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ min_value: 1, numeric: true },"name":"Default Booking Duration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"label":"Default Booking Duration (minutes)","min":"1","type":"number"},model:{value:(_vm.userPreference.defaultBookingDuration),callback:function ($$v) {_vm.$set(_vm.userPreference, "defaultBookingDuration", _vm._n($$v))},expression:"userPreference.defaultBookingDuration"}})]}}],null,true)}),_c('custom-label',{attrs:{"item-field-name":"Opening Hours"}}),_c('v-row',[_c('v-col',{attrs:{"cols":3}},[_c('v-select',{attrs:{"outlined":"","items":_vm.time},model:{value:(_vm.localStart),callback:function ($$v) {_vm.localStart=$$v},expression:"localStart"}})],1),_c('v-col',{attrs:{"cols":3}},[_c('v-select',{attrs:{"outlined":"","items":_vm.time},model:{value:(_vm.localEnd),callback:function ($$v) {_vm.localEnd=$$v},expression:"localEnd"}})],1)],1),_c('v-checkbox',{staticClass:"px-3 flex-grow-0 flex-shrink-0",attrs:{"color":"primary","label":"To send confirmation SMS when booking is created by client"},model:{value:(_vm.userPreference.isSendBookingSuccessfulSms),callback:function ($$v) {_vm.$set(_vm.userPreference, "isSendBookingSuccessfulSms", $$v)},expression:"userPreference.isSendBookingSuccessfulSms"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":invalid,"color":"primary"},on:{"click":function($event){validate().then(_vm.updateUserPreference)}}},[_vm._v(" Update ")])],1)]}}],null,false,4231069027)})],1):_vm._e(),(_vm.printOrderReceiptPreference)?_c('v-card',{staticClass:"mt-4"},[_c('ValidationObserver',{ref:"printOrderReceiptPreferenceForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('v-card-title',[_vm._v("Printer Setting")]),_c('v-card-text',[_c('v-checkbox',{staticClass:"px-3 flex-grow-0 flex-shrink-0",attrs:{"color":"primary","label":"Print Receipt and Restaurant Order By Pos"},model:{value:(_vm.printOrderReceiptPreference.autoPrintFromPos),callback:function ($$v) {_vm.$set(_vm.printOrderReceiptPreference, "autoPrintFromPos", $$v)},expression:"printOrderReceiptPreference.autoPrintFromPos"}}),_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Default Booking Duration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Receipt Printer MacAddress","type":"text"},model:{value:(_vm.printOrderReceiptPreference.printerMacAddress),callback:function ($$v) {_vm.$set(_vm.printOrderReceiptPreference, "printerMacAddress", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"printOrderReceiptPreference.printerMacAddress"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"Default Booking Duration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Restaurant Order Printer MacAddress","type":"text"},model:{value:(_vm.printOrderReceiptPreference.restaurantOrderPrinterMacAddress),callback:function ($$v) {_vm.$set(_vm.printOrderReceiptPreference, "restaurantOrderPrinterMacAddress", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"printOrderReceiptPreference.restaurantOrderPrinterMacAddress"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":invalid,"color":"primary"},on:{"click":function($event){validate().then(_vm.updatePrintOrderReceiptPreference)}}},[_vm._v(" Update ")])],1)]}}],null,false,3675032809)})],1):_vm._e(),_c('v-spacer',{staticClass:"mb-5"}),_c('v-card',[_c('ValidationObserver',{ref:"userPreferenceForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('v-card-title',[_vm._v("Statutory holiday ")]),_c('v-card-text',[_c('CooperateCalendarEvent')],1)]}}])})],1),_c('v-dialog',{attrs:{"width":"60%","max-width":"100vw"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[(_vm.editDialog && _vm.type === 'colorOptions')?_c('color-options-card',{attrs:{"item":_vm.editItem},on:{"add":function (x) {
          _vm.editDialogCallBack && _vm.editDialogCallBack(x);
          _vm.editDialog = false;
        },"close":function () {
          _vm.editDialogCallBack && _vm.editDialogCallBack(null);
          _vm.editDialog = false;
        }}}):(_vm.editDialog)?_c('AddPhotoCard',{attrs:{"item":_vm.editItem},on:{"add":function (x) {
          _vm.editDialogCallBack && _vm.editDialogCallBack(x);
          _vm.editDialog = false;
        },"delete":function (x) {
          _vm.deleteImg(x);
        },"close":function () {
          _vm.editDialogCallBack && _vm.editDialogCallBack(null);
          _vm.editDialog = false;
        }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }