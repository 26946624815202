var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"d-flex align-center flex-wrap pa-4 flex-column flex-sm-row",staticStyle:{"gap":"15px"}},[_c('h3',[_vm._v("Add New:")]),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Date","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"max-width":"200px"},attrs:{"label":"Date","dense":"","outlined":"","hide-details":"","prepend-inner-icon":"mdi-calendar","error-messages":errors},model:{value:(_vm.model.date),callback:function ($$v) {_vm.$set(_vm.model, "date", $$v)},expression:"model.date"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"change":function($event){_vm.menu = false}},model:{value:(_vm.model.date),callback:function ($$v) {_vm.$set(_vm.model, "date", $$v)},expression:"model.date"}})],1),_c('ValidationProvider',{attrs:{"name":"Event Name","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"label":"Event Name","hide-details":"","outlined":"","dense":"","error-messages":errors},model:{value:(_vm.model.eventName),callback:function ($$v) {_vm.$set(_vm.model, "eventName", $$v)},expression:"model.eventName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Remarks","rules":{ required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Remarks","hide-details":"","outlined":"","dense":"","error-messages":errors},model:{value:(_vm.model.remarks),callback:function ($$v) {_vm.$set(_vm.model, "remarks", $$v)},expression:"model.remarks"}})]}}],null,true)}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addItem}},[_vm._v("Add")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }