import { IAttachment } from '@/api/attachment';
import { IClientProfile, IMasterProfile } from '@/api/auth/membership';
import { crudUpdate } from '@/api/crud';
import {
  GlobalSchemaFields,
  IDateRange,
  populatedArrayCtor,
  populatedCtor,
  populatedListCtor,
} from '@/api/mongooseTypes';
import { IServiceProduct } from '@/api/serviceProduct';
import { ITicketsPackage } from '@/api/ticketPackage';
import { ITimetable } from '@/api/timetable';
import { merge } from 'lodash';

// export async function getSample(api, id) {
//   return (await api.get(`toca/master/booking/validStayTime/${id}`)).data;
// }

// export async function postSample(api, id, payload) {
//   return (await api.post(`/toca/admin/booking/settleBooking/${id}`, payload)).data;
// }

export async function patchPurchaseOrder(api, id, data) {
  return (await api.patch(`/toca/purchase-orders/${id}`, data)).data;
}
