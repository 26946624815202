<template>
  <ListView
    title="Warehouses"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :enable-create="false"
    :page.sync="page"
    :search.sync="search"
    :data="data"
    :header="header"
  >
    <!-- <template v-slot:item.stock="{ item }">
      {{
        (item._warehouseStocks && item._warehouseStocks.reduce((acc, x) => acc + x._stock, 0)) ||
        '-'
      }}
    </template> -->
  </ListView>
</template>

<script>
import ListView from '@/components/ListView';
import { roles } from '@/data/constants';
import { mapGetters } from 'vuex';
import { getStores } from '@/api/store';
import api from '@/api/';
import { crudList } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';

export default {
  name: 'Store',
  data() {
    return {
      header: [
        { text: 'Name', value: 'name' },
        // { text: 'Stock', value: 'stock' },
      ],
      data: [],
      search: '',
      // populate: { path: '_warehouseStocks', select: '_stock' },
      // ================= warning ==================== 14-08-2024
      // to prevent exceeding the time limit, aim for a loading time of around 40 seconds for PROD with 10 stores and 7053 products
      // ================= warning ====================
    };
  },
  mixins: [listViewPageMixin],
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin']),

    checkRole() {
      return this.role !== roles.master;
    },
    restfulURL() {
      return RESTFUL.warehouses.list;
    },
    // limit() {
    //   return 20;
    // },
  },
  methods: {
    actionCreate() {
      this.$router.push({ name: 'warehouse', params: { id: 'new' } });
    },
    actionDetails(item) {
      this.$router.push({ name: 'warehouse', params: { id: item?._id } });
    },
  },
};
</script>
