<template>
  <div>
    <v-row>
      <v-col class="selectListCol" cols="auto">
        <div class="d-flex">
          <v-select class="selectedListBar" outlined :items="range" v-model="selectedRange" />
        </div>
      </v-col>
      <template v-if="selectedRange == 'selectDate'">
        <div class="d-flex align-center selectListCol">
          <v-menu
            v-model="startTimeDialog"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedStart"
                label="From"
                prepend-icon="mdi-calendar"
                style="min-width: max-content"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="selectedStart"
              @input="startTimeDialog = false"
              header-color="primary"
              :max="computedMaxEndTime"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="d-flex align-center selectListCol">
          <!--            <input type="date" class="input" v-model="selectedEnd" @input="passEndTime()" />-->
          <v-menu
            v-model="endTimeDialog"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedEnd"
                label="To"
                prepend-icon="mdi-calendar"
                style="max-width: max-content"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              header-color="primary"
              v-model="selectedEnd"
              @input="endTimeDialog = false"
              :max="computedMaxEndTime"
            >
            </v-date-picker>
          </v-menu>
        </div>
      </template>
      <template v-if="selectedRange == 'selectMonth'">
        <div class="d-flex align-center selectListCol">
          <v-menu
            v-model="startTimeDialog"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedMonth"
                prepend-icon="mdi-calendar"
                style="min-width: max-content"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="selectedMonth"
              type="month"
              @input="startTimeDialog = false"
              header-color="primary"
              :max="computedMaxEndTime"
            ></v-date-picker>
          </v-menu>
        </div>
      </template>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') }),
    },
    defaultRange: '',
  },
  data() {
    return {
      localTimeRange: {
        start: null,
        end: null,
      },
      localTimeRange_Month: {
        start: null,
        end: null,
      },
      startTimeDialog: false,
      endTimeDialog: false,
      selectedRange: { day: 0 },
      range: [
        { text: 'Today', value: { day: 0 } },
        { text: 'Date', value: 'selectDate' },
        { text: 'Month', value: 'selectMonth' },
        // { text: 'Yesterday', value: { day: 1 } },
        // { text: 'Last 7 days', value: { day: 7, isRange: true } },
        // { text: 'Last 1 month', value: { day: 31, isRange: true } },
      ],
      isMounted: false,
    };
  },
  computed: {
    ...mapGetters('dateFilter', ['storedType', 'storedStart', 'storedEnd', 'storedMonth']),
    computedMaxEndTime() {
      return moment().format('YYYY-MM-DD');
    },
    selectedStart: {
      get() {
        return moment(this.localTimeRange.start).format('YYYY-MM-DD');
      },
      set(v) {
        this.localTimeRange.start = +moment(v).startOf('day');
        this.updateStoredStart(v);
        this.$emit('input', this.localTimeRange);
      },
    },
    selectedEnd: {
      get() {
        return moment(this.localTimeRange.end).format('YYYY-MM-DD');
      },
      set(v) {
        this.localTimeRange.end = +moment(v).endOf('day');
        this.updateStoredEnd(v);
        this.$emit('input', this.localTimeRange);
      },
    },
    selectedMonth: {
      get() {
        return moment(this.localTimeRange_Month.start).format('YYYY-MM');
      },
      set(v) {
        this.localTimeRange_Month.start = +moment(v).startOf('month');
        this.localTimeRange_Month.end = +moment(v).endOf('month');
        this.updateStoredMonth(v);
        this.$emit('input', this.localTimeRange_Month);
      },
    },
  },
  mounted() {
    if (this.storedType != null) this.selectedRange = this.storedType;
    else if (this.defaultRange) this.selectedRange = this.defaultRange;

    this.isMounted = true;
  },
  watch: {
    value(v) {
      this.localTimeRange = v;
    },
    selectedRange: {
      immediate: true,
      async handler(v) {
        if (v) {
          switch (v) {
            case 'selectDate':
              if (this.isMounted) this.updateStoredType(v);
              this.selectedStart = this.storedStart || moment();
              this.selectedEnd = this.storedEnd || moment();
              break;

            case 'selectMonth':
              if (this.isMounted) this.updateStoredType(v);
              this.selectedMonth = this.storedMonth || moment();
              break;

            default:
              if (this.isMounted) this.updateStoredType(v);
              const { day, isRange } = v;
              if (Number.isInteger(day)) {
                this.localTimeRange.start = +moment().subtract(day, 'day').startOf('day'); //the value should be subtract 1
                if (isRange) {
                  this.localTimeRange.end = +moment().endOf('day');
                } else {
                  this.localTimeRange.end = +moment(this.localTimeRange.start).endOf('day');
                }
              }
              this.$emit('input', this.localTimeRange);
              break;
          }
        }
      },
    },
  },

  methods: {
    ...mapMutations('dateFilter', [
      'updateStoredType',
      'updateStoredStart',
      'updateStoredEnd',
      'updateStoredMonth',
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
