<template>
  <v-card>
    <v-card-title style="align-items: center">
      Upload Reference Photo
      <v-spacer />
      <v-btn
        icon
        @click="
          () => {
            $emit('close');
          }
        "
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text style="margin-bottom: -50px">
      <v-row>
        <v-col cols="4">
          <div v-if="previewImg">
            <img
              :src="previewImg"
              style="max-width: 100%; object-fit: contain"
              height="150"
              class="mb-2"
            />
          </div>
          <div v-else-if="item" class="d-flex align-center">
            <v-img
              :src="attnThumbHref(item.img, 128)"
              max-height="128px"
              max-width="128px"
              class="mb-2"
            />
            <v-btn v-if="item.img" icon @click="$emit('delete', item)"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
          <v-file-input
            outlined
            placeholder="Image"
            v-model="newImg"
            accept="image/jpeg,image/png"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field outlined label="label" v-model="label" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="
          () => {
            $emit('add', { img: newImg || (item && item.img), label: label });
          }
        "
        :disabled="!label && !item"
        >Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { attnThumbHref } from '@/services/filters';
export default {
  name: 'SystemConfigDialog',
  props: {
    item: {},
  },
  data() {
    return {
      newImg: null,
      previewImg: null,
      label: null,
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(v) {
        if (v) {
          this.label = v.label;
        }
      },
    },
    newImg: {
      immediate: true,
      async handler(v) {
        if (v instanceof File) {
          this.previewImg = URL.createObjectURL(v);
          await this.$nextTick();
          URL.revokeObjectURL(this.previewImg);
        }
      },
    },
  },
  methods: {
    attnThumbHref,
  },
};
</script>

<style scoped></style>
