<template>
  <v-container class="pa-3">
    <div style="max-width: 600px" class="d-flex justify-space-between mb-3">
      <span style="align-self: center" class="mainTitle">My Profile</span>
      <v-btn style="align-items: center" @click="changePassword">Change Password</v-btn>
    </div>
    <v-card elevation="4" width="600" class="pa-1" v-if="profile">
      <v-card-title title="Name">
        <v-icon> mdi-account-circle </v-icon>
        <span class="mainTitle" style="font-size: 20px">
          {{ profile.name }}
        </span>
        <span class="textLabel ml-2 d-flex d-sm-none">
          {{ profile.role }}
        </span>
      </v-card-title>
      <v-card-subtitle title="Role">
        <span class="textLabel d-none d-sm-flex">
          {{ profile.role }}
        </span>
        <v-divider></v-divider>
      </v-card-subtitle>

      <v-card-text>
        <!--        <p>-->
        <!--          <v-icon title="Email"> mdi-email </v-icon>-->
        <!--          <span class="textLabel">-->
        <!--            {{ profile.email }}-->
        <!--          </span>-->
        <!--        </p>-->
        <div class="d-flex flex-sm-column justify-space-around" style="gap: 10px">
          <div>
            <v-icon alt="Phone" title="Phone"> mdi-phone </v-icon>
            <span class="textLabel">
              {{ displayPhone(profile.phone, x => x.formatInternational()) || 'No Contact' }}
            </span>
          </div>
          <div>
            <v-icon title="Created at"> mdi-calendar-account </v-icon>
            <span class="textLabel">
              {{ profile.created_at | moment('YYYY-MM-DD') }}
            </span>
          </div>
        </div>
      </v-card-text>
      <div class="ml-sm-5 mb-4 d-flex justify-center justify-sm-start">
        <qrcode-vue v-if="showQR" :value="qrcode" :size="180" level="H" />
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { displayPhone } from '@/services/filters';
import { mapActions, mapGetters } from 'vuex';
import { roles } from '@/data/constants';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'MyProfile',
  components: { QrcodeVue },
  props: {},
  data() {
    return { qrcode: null };
  },
  computed: {
    ...mapGetters('auth', ['user', 'profile']),
    isMaster() {
      return this.profile?.role === roles.master;
    },
    // isClient() {
    //   return this.profile?.role === roles.client;
    // },
    // isCashier() {
    //   return this.profile?.role === roles.cashier;
    // },
    showQR() {
      return this.isMaster;
    },
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    displayPhone,
    changePassword() {
      this.$router.push({ name: 'changeMyPassword' });
    },
  },
  mounted() {
    this.qrcode = this.profile?._id;
  },
};
</script>

<style scoped></style>
