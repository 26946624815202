<template>
  <v-container class="pa-3">
    <div class="mainTitle mb-3">Notification</div>
    <div v-if="isAdmin">
      <ValidationObserver ref="form" v-slot="{ valid }">
        <v-card class="mb-10 pa-5">
          <template>
            <v-card-title>Send notification</v-card-title>
            <v-divider></v-divider>
            <v-card-title>Select recipient</v-card-title>
            <v-row class="pa-0 ma-0">
              <!--              <v-col>-->
              <!--                <v-checkbox v-model="all" label="All Staffs" />-->
              <!--              </v-col>-->
              <!--              <v-col>-->
              <!--                <v-checkbox v-model="allCashiers" label="All Cashiers" />-->
              <!--              </v-col>-->
              <!--              <v-col>-->
              <!--                <v-checkbox v-model="allMasters" label="All Facility" />-->
              <!--              </v-col>-->
              <v-col>
                <v-checkbox v-model="allClients" label="All Customer" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  label="Other recipient"
                  outlined
                  chips
                  small-chips
                  multiple
                  deletable-chips
                  v-model="recipients"
                  :items="autocompleteUsers"
                  item-text="name"
                  item-value="_id"
                  :disabled="allMasters || allCashiers || allClients"
                  :search-input.sync="search"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </template>
          <v-divider></v-divider>
          <ValidationProvider v-slot="{ errors }" name="content" rules="required">
            <v-card-title>Content</v-card-title>
            <v-textarea
              solo
              label="Write the content of your notification..."
              v-model="content"
              :error-messages="errors"
            ></v-textarea>
          </ValidationProvider>
          <div style="display: flex">
            <v-spacer />
            <v-btn color="primary" class="mb-3" @click="sentNotificationToAll" :disabled="!valid">
              Send
            </v-btn>
          </div>
        </v-card>
      </ValidationObserver>
    </div>
    <div>
      <v-card>
        <v-card-title>Notification Details</v-card-title>
        <v-card-text>
          <v-text-field solo filled prepend-inner-icon="mdi-magnify" v-model="searchContent" />
          <v-row class="mb-3 itemFieldName">
            <v-col :cols="isAdmin ? 6 : 9" class="subTitle"> Content</v-col>
            <v-col v-if="isAdmin" class="subTitle" cols="3"> Recipient</v-col>
            <v-col class="subTitle" cols="3"> Date </v-col>
          </v-row>
          <v-list>
            <v-list-item v-for="(item, i) in list.docs" :key="i" class="pa-0">
              <v-row class="mb-3 pb-2 text" style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)">
                <v-col :cols="isAdmin ? 6 : 9">{{ item.content }}</v-col>
                <v-col v-if="isAdmin" cols="3"
                  ><div class="text-truncate">{{ computedRecipients(item) }}</div></v-col
                >
                <v-col cols="3">{{ item.created_at | moment }}</v-col>
              </v-row>
            </v-list-item>
          </v-list>
          <v-pagination class="mt-4" v-model="pageVal" :length="list.pages || 1" />
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { getId } from '@/services/filters';
import { mapGetters } from 'vuex';
import api from '@/api';
import { crudList } from '@/api/crud';
import {
  listNotification,
  listStaffNotification,
  notificationMarkAsRead,
  sendNotificationToAll,
} from '@/api/notification';
import { debounce } from 'lodash';
import { reCheckIfHasUnreadNotification } from '@/plugins/app-state';
import store from '@/store';

export default {
  name: 'notification',
  data() {
    return {
      content: null,
      list: { docs: [], offset: 0, page: 1, pages: 1, limit: 9 },
      recipients: [],
      autocompleteUsers: [],
      page: 1,
      allCashiers: false,
      allMasters: false,
      allClients: false,
      search: '',
      searchContent: '',
      debouncedSearch: debounce(this.showTeamList, 400),
      debouncedSearchContent: debounce(this.reload, 400),
    };
  },
  mounted() {
    this.reload();
  },
  watch: {
    search: {
      handler(v) {
        this.debouncedSearch();
      },
    },
    searchContent: {
      handler(v) {
        this.debouncedSearchContent();
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin', 'isMaster', 'isCashier', 'profile']),
    ...mapGetters('notification', ['hasUnreadNotification']),
    all: {
      get() {
        return this.allCashiers && this.allMasters && this.allClients;
      },
      set(isTrue) {
        if (isTrue) {
          this.allCashiers = true;
          this.allMasters = true;
          this.allClients = true;
        } else {
          this.allCashiers = false;
          this.allMasters = false;
          this.allClients = false;
        }
      },
    },
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.reload();
      },
    },
    header() {
      return [
        { text: 'Content', value: 'content' },
        { text: 'Date', value: 'created_at' },
        { text: 'Transaction ID', value: 'to' },
      ];
    },
  },
  methods: {
    alertMessage(type, err) {
      if (type === 'success') {
        return this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Sent',
          type: 'success',
          color: 'success',
        });
      } else {
        return this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    async showTeamList() {
      const options = {
        filter: { role: ['toca.cashier', 'toca.master', 'toca.client'] },
        limit: 10,
      };
      if (this.search) options['search'] = this.search;
      let res = await crudList(api, 'toca/admin/profiles', options);
      const arr = this.autocompleteUsers.concat(res.docs);
      const id_arr = arr.map(x => x._id);
      const uniqueIdArr = [...new Set(id_arr)];
      let newArr = [];
      for (const user of arr) {
        if (uniqueIdArr.includes(user._id) && !newArr.some(el => el._id === user._id)) {
          newArr.push(user);
        }
      }
      this.autocompleteUsers = newArr;
    },
    async sentNotificationToAll() {
      try {
        if (this.all) {
          await sendNotificationToAll(api, this.content, 'all');
        } else if (this.allCashiers) {
          await sendNotificationToAll(api, this.content, 'cashiers');
        } else if (this.allMasters) {
          await sendNotificationToAll(api, this.content, 'masters');
        } else if (this.allClients) {
          await sendNotificationToAll(api, this.content, 'clients');
        } else if (this.recipients.length > 0) {
          await sendNotificationToAll(api, this.content, this.recipients);
        } else {
          this.alertMessage('error', 'Please select at least one recipient');
          return;
        }
        this.alertMessage('success');
        await this.reload();
      } catch (err) {
        this.alertMessage('', err);
      }
    },
    async reload() {
      let temp;
      if (this.isAdmin) {
        temp = await listNotification(api, {
          sort: { created_at: -1 },
          page: this.page,
          search: this.searchContent,
        });
      } else if (this.isCashier || this.isMaster) {
        temp = await listStaffNotification(api, this.profile._id, {
          page: this.page,
          search: this.searchContent,
        });
      }
      this.list = temp || [];
      const ids = this.list.docs.map(x => getId(x));
      if (ids.length && (this.isCashier || this.isMaster)) {
        await notificationMarkAsRead(api, ids);
        await reCheckIfHasUnreadNotification(this.$store, api);
      }
      // await this.$store.dispatch('notification/readNotify');
      if (this.isAdmin) {
        await this.showTeamList();
      }
    },
    computedRecipients(item) {
      const recipientType = item?.payload?.recipientType;
      let str = '';
      if (recipientType && !Array.isArray(recipientType)) {
        return recipientType;
      } else {
        const rpts = item?.payload?.to;
        if (rpts && Array.isArray(rpts)) {
          for (const [i, rpt] of rpts.entries()) {
            str = str + `${rpt.name}` + `${rpts[i + 1] ? ', ' : ''}`;
          }
        }
      }
      return str;
    },
  },
};
</script>

<style scoped></style>
