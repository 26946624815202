<template>
  <ListView
    title="Discount"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :enable-create="checkRole"
    :search.sync="search"
    :page.sync="page"
    :data="data"
    :header="header"
  />
</template>

<script>
import ListView from '@/components/ListView';
import { roles } from '@/data/constants';
import { mapGetters } from 'vuex';
import { getStores } from '@/api/store';
import api from '@/api/';
import { crudList } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';

export default {
  name: 'Store',
  data() {
    return {
      header: [
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'type' },
        { text: 'Discount Value', value: 'value' },
      ],
      data: [],
      search: '',
    };
  },
  mixins: [listViewPageMixin],
  computed: {
    ...mapGetters('auth', ['role', 'isAdmin']),

    checkRole() {
      return this.role !== roles.master;
    },
    restfulURL() {
      return RESTFUL.discounts.list;
    },
  },

  methods: {
    // async reload() {
    //   this.data = await (this.isMaster
    //     ? crudList(api, RESTFUL.stores.list)
    //     : crudList(api, RESTFUL.stores.list));
    //   this.data?.sort((a, b) => a?.tableNo - b?.tableNo);
    // },
    // used in mixin

    actionCreate() {
      this.$router.push({ name: 'discount', params: { id: 'new' } });
    },
    actionDetails(item) {
      this.$router.push({ name: 'discount', params: { id: item?._id } });
    },
  },
};
</script>
